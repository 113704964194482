import React, { useState } from 'react';
import AppBarDrawer from '../components/layout/AppBarDrawer';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PLANS_QUERY } from '../GraphQL/queries';
import {
  PURCHASE_PLAN_MUTATION,
  CANCEL_CURRENT_PLAN_MUTATION
} from '../GraphQL/mutations';
import LoadingAPI from '../components/loader/LoadingAPI';
import { useUser } from '../context/UserContext';
import { toast } from 'react-toastify';
import { useCurrentPlan } from '../GraphQL/hooks';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

function PricingPlan() {
  const { loading: currentPlanLoading, data: currentPlanData } =
    useCurrentPlan();
  const { userProfile } = useUser();
  const { data, loading, error } = useQuery(GET_PLANS_QUERY, {
    fetchPolicy: 'no-cache'
  });
  const [planType, setPlanType] = useState('MONTHS');
  const [isLoading, setIsLoading] = useState(false);
  const [purchasePlan] = useMutation(PURCHASE_PLAN_MUTATION);
  const [
    cancelCurrentPlan,
    { data: cancelPlanData, loading: cancelPlanLoading, error: cancelPlanError }
  ] = useMutation(CANCEL_CURRENT_PLAN_MUTATION);

  const handlePurchase = async (planId) => {
    console.log('Plan ID:', planId);
    if (!userProfile?.email) {
      alert('Sorry, you need to log in to continue');
      return;
    }

    setIsLoading(true);

    try {
      const response = await purchasePlan({
        variables: {
          email: userProfile?.email,
          planId
        }
      });

      const purchaseUrl = response?.data?.purchasePlan;
      if (purchaseUrl) {
        window.location.replace(purchaseUrl);
      } else {
        throw new Error('Failed to get purchase URL');
      }
    } catch (error) {
      toast.error(`Failed: ${error.message}`, toastConfig);
      console.error('Failed to purchase plan:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) return <LoadingAPI />;
  if (error) return <p>Error :(</p>;

  const handleCancelPlan = async () => {
    try {
      const response = await cancelCurrentPlan();
      toast.success(response.data.cancelCurrentPlan);
    } catch (err) {
      console.error(err);
      toast.error(`Failed: ${cancelPlanError}`, toastConfig);
    }
  };

  return (
    <>
      <AppBarDrawer>
        <div className="pricing-main py-3 pb-4">
          <div>
            <h3 className="px-5">
              Level Up Your Job Search By Upgrading Today
            </h3>
            <div className="container bg-transparent pricing-content">
              <div className="row justify-content-center px-5 mt-4">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  {/* Slider */}
                  <div className="pricing-radio">
                    <span className="fw-semibold">Monthly</span>
                    <label className="switch-pricing">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setPlanType(
                            planType === 'MONTHS' ? 'YEARS' : 'MONTHS'
                          )
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="fw-semibold">Annually</span>
                  </div>
                  <div
                    className="pricing-wrapper"
                    style={{ marginTop: '25px' }}
                  >
                    {data?.getPlans
                      .filter((plan) => plan.planDuration.unit === planType)
                      .map((plan, index) => {
                        if (plan.price === 0) return null;
                        return (
                          <div
                            key={plan._id}
                            className={`pricing-card ${
                              plan.isRecommended ? 'featured-pricing recom' : ''
                            }`}
                          >
                            <h2
                              className="pricing-title"
                              style={{ fontWeight: '450' }}
                            >
                              {plan.name}
                            </h2>
                            <div className="price-block">
                              <div
                                className="pricing-price crosed"
                                style={{ color: '#aaaaaa' }}
                              >
                                ${(plan.price * 1.2).toFixed(0)}
                              </div>
                              <div
                                className="pricing-price text-primary"
                                style={{
                                  fontSize: '1.8rem',
                                  fontWeight: '430'
                                }}
                              >
                                ${plan.price}/
                                <span>
                                  {plan.planType === 'MONTHS'
                                    ? 'month'
                                    : 'One single payment'}
                                </span>
                              </div>
                            </div>
                            <div className="pricing-divider" />
                            <div
                              id="recommended"
                              className={`pricing-tag bg-primary text-white ${
                                plan.isRecommended ? 'd-block' : 'd-none'
                              }`}
                            >
                              {currentPlanData?.plan?.name === plan.name
                                ? 'Your Current Plan'
                                : plan.isRecommended
                                ? 'Recommended'
                                : ''}
                            </div>
                            <div
                              className="w-layout-grid f-pricing-feature-list"
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr',
                                gap: '18px'
                              }}
                            >
                              {plan.features.map((feature, index) => (
                                <div
                                  key={index}
                                  className="f-pricing-feature-item"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div
                                    className={`w-embed ${
                                      !feature.isExclusive
                                        ? 'f-icon-regular-3 bigger'
                                        : 'f-icon-regular'
                                    }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M12.0002 19.6386C7.78126 19.6386 4.36133 16.2187 4.36133 11.9997C4.36133 7.78077 7.78126 4.36084 12.0002 4.36084C16.2192 4.36084 19.6391 7.78077 19.6391 11.9997C19.6391 16.2187 16.2192 19.6386 12.0002 19.6386ZM11.2386 15.0553L16.6393 9.65383L15.5592 8.57369L11.2386 12.895L9.07758 10.734L7.99744 11.8141L11.2386 15.0553Z"
                                        fill={
                                          feature.isExclusive
                                            ? '#214289'
                                            : '#160042'
                                        }
                                      />
                                    </svg>
                                  </div>
                                  <div
                                    className={`f-paragraph-small-2 ${
                                      feature.isExclusive &&
                                      'f-text-color-gray-600 bigger'
                                    }`}
                                    style={{
                                      color: feature.isExclusive
                                        ? '#214289'
                                        : 'black',
                                      fontWeight: feature.isExclusive
                                        ? 'bold'
                                        : 'normal',
                                      fontSize: feature.isExclusive
                                        ? '0.9rem'
                                        : '0.85rem'
                                    }}
                                  >
                                    {feature.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                            {currentPlanData?.plan?._id === plan._id ? (
                              <button
                                className="cta-wtrapper plan-link hover btn-secondary"
                                style={{
                                  cursor: 'pointer',
                                  backgroundColor: 'white',
                                  color: 'red',
                                  border: '1.5px solid red',
                                  fontWeight: '650'
                                }}
                                onClick={() => (onClick = { handleCancelPlan })}
                                disabled={cancelPlanLoading}
                              >
                                Cancel Current Plan
                                {cancelPlanLoading ? (
                                  <CircularProgress size="15px" />
                                ) : (
                                  ''
                                )}
                              </button>
                            ) : (
                              <button
                                className="cta-wtrapper plan-link hover btn-secondary"
                                style={{
                                  cursor: 'pointer',
                                  backgroundColor: 'white',
                                  color: '#069CCE',
                                  border: '1.5px solid #069CCE',
                                  fontWeight: '650'
                                }}
                                onClick={() => handlePurchase(plan._id)}
                                disabled={isLoading}
                              >
                                Get Instant Access
                              </button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppBarDrawer>
    </>
  );
}

export default PricingPlan;
