import React, { useState } from 'react';
import AppBarDrawer from '../components/layout/AppBarDrawer';
import SpeedoMeter from '../components/speedometer/SpeedoMeter';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { URL } from '../config/URL';
import jar from '../helpers/cookies';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const RateJobExp = () => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const bearerToken = jar.get('token');
  const jobExpAPI = `${URL}/analytics/quiz`;

  const questions = [
    {
      id: 1,
      quest: 'How is your job search going?',
      options: ['It’s going well', 'It’s getting better', 'Needs improvements']
    },
    {
      id: 2,
      quest: 'How many interviews have you received?',
      options: ['More than 10 >', 'Less than 5 <', 'More than 20 >']
    },
    {
      id: 3,
      quest: 'Are Recruiter reaching out to you?',
      options: ['Yes', 'Not Yet', 'Somehow']
    }
  ];

  const handleOptionChange = (questionId, option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [questionId]: option
    }));

    submitQuestion(questionId, option);
  };

  const submitQuestion = async (questionId, answer) => {
    try {
      const response = await axios.post(
        jobExpAPI,
        {
          question: questions[currentQuestionIndex].quest,
          options: questions[currentQuestionIndex].options,
          answer
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );

      console.log('API Response:', response.data);

      if (currentQuestionIndex === questions.length - 1) {
        toast.success('Submitted successfully!', toastConfig);
      } else {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      }
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  return (
    <AppBarDrawer>
      <div className="container-fluid user-exp-main">
        <div className="row">
          <div className="col-12">
            <SpeedoMeter />
          </div>
        </div>
        <form>
          <div className="row justify-content-around px-lg-5 px-md-4 px-3">
            {questions.map((question) => (
              <div
                key={question.id}
                className="col-lg-3 col-md-4 col-12 questionare-card p-3"
              >
                <p className="quest">{question.quest}</p>
                {question.options.map((option) => (
                  <div key={option} className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`quest_id_${option}`}
                      name={`question_${question.id}`}
                      value={option}
                      checked={selectedOptions[question.id] === option}
                      onChange={() => handleOptionChange(question.id, option)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`quest_id_${option}`}
                    >
                      {option}
                    </label>
                    <br />
                  </div>
                ))}
              </div>
            ))}
            <div className="col-12 py-4 text-center">
              <Link to="/dashboard" className="btn btn-primary px-5">
                Done
              </Link>
            </div>
          </div>
        </form>
      </div>
    </AppBarDrawer>
  );
};

export default RateJobExp;
