import { useNavigate, useSearchParams } from 'react-router-dom';
import jar from '../helpers/cookies';
import { useEffect } from 'react';
import React from 'react';
import { jwtDecode } from 'jwt-decode';

export default function AuthCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      return navigate('/login?error=invalid_token');
    }

    // Decode the JWT token
    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.error('Invalid token:', error);
      return navigate('/login?error=invalid_token');
    }

    // Check if the token is expired
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      // If token is expired, redirect to login
      return navigate('/login?error=token_expired');
    }

    // Check if the decoded token has isNewUser: true
    if (decodedToken && decodedToken.isNewUser) {
      navigate('/onboarding'); // Navigate to onboarding if isNewUser is true
    } else {
      navigate('/dashboard'); // Otherwise, navigate to dashboard
    }

    // Store the token in cookies
    jar.set('token', token, {
      maxAge: 21600,
      path: '/'
    });
  }, [token, navigate]);

  return <>{token}</>;
}
