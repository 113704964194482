import React, { useEffect, useState } from 'react';
import AppBarDrawer from '../components/layout/AppBarDrawer';
import RatingModal from '../components/RatingModal';
import { Link } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import sad_face from '../images/sadAnimation.gif';
import Loading from '../components/loader/Loading';
import { useUser } from '../context/UserContext';
import { useQuery } from '@apollo/client';
import { GET_PLANS_QUERY } from '../GraphQL/queries';
import Quotes from '../components/quote/Quotes';
import { useCurrentPlan } from '../GraphQL/hooks';
import { CircularProgress } from '@mui/material';

// charts

export const options = {
  title: 'Daily Limit',
  pieHole: 0.8,
  pieSliceTextStyle: {
    color: 'transparent'
  },
  colors: ['white', '#214289'],
  legend: 'none',
  width: 100,
  height: 100,
  backgroundColor: 'transparent',
  chartArea: { width: '100%', height: '80%' }
  // pieStartAngle: 150,
};

export const options2 = {
  title: 'Total Applied Jobs',
  pieHole: 0.8,
  pieSliceTextStyle: {
    color: 'transparent'
  },
  colors: ['white', '#214289'],
  legend: 'none',
  width: 100,
  height: 100,
  backgroundColor: 'transparent',
  chartArea: { width: '100%', height: '80%' }
  // pieStartAngle: 150,
};

// Plans Actication
function Home() {
  const { userProfile, appliedJobsData, loading } = useUser();
  const [totalAppliedJobs, setTotalAppliedJobs] = useState(0);
  const [appliedJobsToday, setAppliedJobsToday] = useState(0);
  const { loading: loadingCurrentPlan, data: planData } = useCurrentPlan();
  // const { data: plansData, loading: plansLoading } = useQuery(GET_PLANS_QUERY);

  useEffect(() => {
    const length = appliedJobsData?.length || 0;
    setTotalAppliedJobs(length);

    const today = new Date().toDateString();
    const appliedJobsToday =
      appliedJobsData?.filter((job) => {
        const appliedDate = new Date(job.applied_on).toDateString();
        return appliedDate === today;
      }).length || 0;
    setAppliedJobsToday(appliedJobsToday);
  }, [appliedJobsData]);

  const dailyApplyLimit =
    userProfile?.activation?.daily_apply_limit?.tokens ||
    userProfile?.activation?.max_apply_limit;

  const usedToday = appliedJobsToday || 0;
  const remainingToday = dailyApplyLimit ? dailyApplyLimit - usedToday : 0;
  const totalTokensAllocated = dailyApplyLimit
    ? calculateTotalAllocatedTokens(userProfile?.createdAt, dailyApplyLimit)
    : 0;

  function calculateTotalAllocatedTokens(createdAt, dailyLimit) {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const daysSinceCreation = Math.floor(
      (currentDate - createdDate) / (1000 * 60 * 60 * 24)
    );
    const totalAllocatedTokens = dailyLimit * daysSinceCreation;
    return totalAllocatedTokens;
  }

  // Calculate the percentages
  const appliedPercentage = ((usedToday / dailyApplyLimit) * 100).toFixed(0);
  const remainingPercentage = (
    (remainingToday / dailyApplyLimit) *
    100
  ).toFixed(0);
  const totalAppliedPercentage = (
    (totalAppliedJobs / totalTokensAllocated) *
    100
  ).toFixed(0);
  const totalAllocatedPercentage = (
    (totalTokensAllocated / totalTokensAllocated) *
    100
  ).toFixed(0);

  const dailyData = [
    ['Used', 'Remaining'],
    [`${remainingPercentage || 0}% Remaining`, remainingToday],
    [`${appliedPercentage || 0}% Used`, usedToday]
  ];

  const total = [
    ['Total Used Token', 'Total Allocated Token'],
    [`${totalAllocatedPercentage || 0}% Allocated`, totalTokensAllocated],
    [`${totalAppliedPercentage || 0}% Used`, totalAppliedJobs]
  ];

  return loading ? (
    <Loading />
  ) : (
    <AppBarDrawer>
      <div className="container-fluid page-bg my-0">
        <div className="row px-2 pt-2">
          <div className="col-lg-4 col-md-6 col-12 px-1 mb-2">
            <div className="p-2 home-card-main">
              <p className="bg-white py-1 text-center rounded">Daily Limit</p>
              <div className="px-2 mt-2">
                <div className="text-white">
                  <div className="d-flex justify-content-between p-0 stats-fs-bold">
                    <p className="fw-bold mb-0 pb-0">Total Apply Tokens</p>
                    <p className="fw-bold mb-0 pb-0 text-end">
                      {planData?.availableTokens || 0}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between p-0 stats-fs-bold">
                    <p className="fw-bold mb-0 pb-0">Remaining Tokens</p>
                    <p className="fw-bold mb-0 pb-0 text-end">
                      {planData?.availableTokens || 0}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between pt-1 stats-fs-normal">
                    <p className="text-center my-auto">
                      {isNaN(appliedPercentage) || undefined || null
                        ? 0
                        : appliedPercentage}
                      % used today.
                    </p>
                    <div>
                      <Chart
                        chartType="PieChart"
                        data={dailyData}
                        options={options}
                      />
                    </div>
                    <p className="text-center my-auto">
                      {appliedJobsToday || 0} jobs applied
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-1 mb-2">
            <div className="p-2 home-card-main">
              <p className="bg-white py-1 text-center rounded">Plan Details</p>
              <div className="px-2 mt-2">
                <div className="text-white">
                  <div className="d-flex justify-content-between p-0 mb-2 stats-fs-bold">
                    <p className="fw-bold mb-0 pb-0">Plan Name</p>
                    <p className="fw-bold mb-0 pb-0 text-uppercase">
                      {loadingCurrentPlan ? (
                        <CircularProgress size="15px" />
                      ) : (
                        planData?.plan?.name
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between p-0 stats-fs-bold">
                    <p className="fw-bold mb-0 pb-0">Total Apply Tokens</p>
                    <p className="fw-bold mb-0 pb-0 text-end">
                      {planData?.availableTokens || 0}
                    </p>
                  </div>
                  {/* <div className="text-center pt-4 my-2 stats-fs-normal">
                    <p className="mx-2">
                      It seems like your apply tokens are nearly ending!
                      <strong> Upgrade now</strong> to keep going.
                    </p>
                  </div> */}
                </div>
              </div>
              {/* <div className="d-flex justify-content-between gap-1 mt-3 pt-2 px-0">
                {!plansLoading &&
                  plansData.getPlans.map((plan) => (
                    <Link
                      key={plan.planName}
                      to={plan.checkout}
                      type="button"
                      className={`col btn btn-light mx-auto btn-plans-home ${
                        plan.recommended
                          ? 'bg-primary text-white'
                          : 'bg-white text-primary'
                      } px-2`}
                      disabled={userProfile?.activation?.title === plan.name}
                      data-toggle="tooltip"
                      title={
                        userProfile?.activation?.title === plan.name
                          ? 'Already Purchased'
                          : ''
                      }
                    >
                      {plan.name}
                    </Link>
                  ))}
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-1 mb-2">
            <div className="p-2 home-card-main">
              <p className="bg-white py-1 text-center rounded">
                Total Applied Jobs
              </p>
              <div className="px-2 mt-2">
                <div className="text-white">
                  <div className="d-flex justify-content-between p-0 stats-fs-bold">
                    <p className="fw-bold mb-0 pb-0">Total Applied Jobs</p>
                    <p className="fw-bold mb-0 pb-0 text-end">
                      {totalAppliedJobs}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center pt-3 stats-fs-normal">
                    <p className="text-center my-auto">
                      {isNaN(totalAppliedPercentage) || undefined || null
                        ? 0
                        : totalAppliedPercentage}
                      % used in total.
                    </p>
                    <div>
                      <Chart
                        chartType="PieChart"
                        data={total}
                        options={options2}
                      />
                    </div>
                    {/* <div className="my-auto stats-fs-normal">
                      <p className="text-end my-auto">
                        Linkedin - {getAppliedJobs('linkedin')}
                      </p>
                      <p className="text-end my-auto">
                        Indeed - {getAppliedJobs('indeed')}
                      </p>
                      <p className="text-end my-auto">
                        ZipRecruiter - {getAppliedJobs('ziprecruiter')}
                      </p>
                      <p className="text-end my-auto">
                        Glassdoor - {getAppliedJobs('glassdoor')}
                      </p>
                      <p className="text-end my-auto">
                        Dice - {getAppliedJobs('dice')}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-2 my-5">
          <div className="col-8 mx-auto my-5">
            <Quotes />
          </div>
        </div>
      </div>
      {/* Modal */}
      <RatingModal open={false} />
      {/* {!extensionInstalled && (
        <InstallExtension open={extensionModal} onClose={handleCloseModal} />
      )} */}
    </AppBarDrawer>
  );
}

const InstallExtension = ({ open, onClose, handleCloseModal }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          p: 4
        }}
      >
        <div className="modal-content text-center py-4 px-3 border-0">
          <div className="modal-header py-0">
            <img
              className="mx-auto py-0 my-0"
              src={sad_face}
              alt="Sad Face Animation"
            />
          </div>
          <div className="modal-body px-5">
            <h6 className="px-4">
              Uh-oh - It seems you haven&apos;t installed extension yet.
            </h6>
          </div>
          <div className="modal-footer text-center py-0">
            <a
              href="https://chromewebstore.google.com/detail/savetimeapply-an-ai-job-a/nkogfdnicckljpapaknjjngonddcihoc"
              target="_blank"
              rel="noreferrer"
              type="button"
              className="btn btn-primary mx-auto px-5"
              onClick={handleCloseModal}
            >
              Install Extension Now
            </a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

InstallExtension.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func
};

export default Home;
