import { ApolloLink } from '@apollo/client';
import jar from '../helpers/cookies';

export const AuthLink = new ApolloLink((operation, forward) => {
  const token = jar.get('token');

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }));

  return forward(operation);
});
