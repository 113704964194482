import React, { useState, useEffect, useMemo } from 'react';
import { useUser } from '../../context/UserContext';
import { Tooltip } from 'react-tooltip';
import LoadingAPI from '../../components/loader/LoadingAPI';
import { useQuery, useMutation } from '@apollo/client';
import { QUEUED_JOBS_QUERY } from '../../GraphQL/queries';
import {
  RMOVE_QUEUED_JOB_MUTATION,
  ENABLE_AUTOAPPLY_MUTATION
} from '../../GraphQL/mutations';
import { toast } from 'react-toastify';
import { AutoApplyStatus } from '../../GraphQL/hooks';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const Queued = () => {
  const { data: initialAutoApplyStatus, refetch: refetchAutoApplyStatus } =
    AutoApplyStatus();

  const { resumeList } = useUser();
  const [selectedCV, setSelectedCV] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState();

  const [page, setPage] = useState(1);
  const limit = 10;

  // Construct query variables conditionally
  const queryVariables = useMemo(
    () => ({
      page,
      limit,
      ...(searchTerm && { search: searchTerm }),
      ...(selectedCV && { resumeId: selectedCV }),
      ...(status && { statuses: status })
    }),
    [page, limit, searchTerm, selectedCV, status]
  );

  // Query for queued jobs
  const { loading, error, data, fetchMore, refetch } = useQuery(
    QUEUED_JOBS_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        queuedJobsInput: queryVariables
      },
      notifyOnNetworkStatusChange: true
    }
  );

  console.log('Queued Jobs:', error ? error.message : 'Success');

  useEffect(() => {
    refetch();
  }, [selectedCV, status, searchTerm]);

  const [removeQueuedJobs] = useMutation(RMOVE_QUEUED_JOB_MUTATION);

  // Function to handle the removal of queued jobs
  const handleRemoveQueuedJob = async (queuedJobId) => {
    try {
      await removeQueuedJobs({
        variables: {
          removeQueuedJobsInput: {
            queuedJobIds: queuedJobId
          }
        }
      });
      toast.success('Job removed successfully!', toastConfig);
      // After removal, refetch the queued jobs
      refetch();
    } catch (error) {
      console.error('Error removing queued job:', error);
    }
  };

  // console.log('initialAutoApplyStatus', initialAutoApplyStatus);

  // Auto Apply
  const [autoApply, setAutoApply] = useState(!initialAutoApplyStatus);
  const [changeAutoApplyStatus] = useMutation(ENABLE_AUTOAPPLY_MUTATION);

  useEffect(() => {
    if (initialAutoApplyStatus) {
      setAutoApply(!initialAutoApplyStatus);
    }
  }, [initialAutoApplyStatus]);

  const handleAutoApply = () => {
    const newStatus = !autoApply;
    changeAutoApplyStatus({
      variables: { changeAutoApplyStatusInput: { pauseAutoApply: !newStatus } }
    })
      .then(({ data }) => {
        if (data.changeAutoApplyStatus === 'success') {
          toast.success('Auto Apply Status Changed Successfully', toastConfig);
          refetchAutoApplyStatus().then(({ data }) => {
            setAutoApply(!data.isAutoApplyPaused);
          });
        }
      })
      .catch((error) => {
        console.error('Failed to change Auto Apply Status:', error);
        toast.error('Failed to change Auto Apply Status', toastConfig);
      });
    setAutoApply(newStatus);
  };
  // console.log('autoApply', autoApply);

  // Load more jobs
  const handleLoadMore = async () => {
    try {
      await fetchMore({
        variables: {
          queuedJobsInput: queryVariables
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            queuedJobs: {
              ...fetchMoreResult.queuedJobs,
              elements: [
                ...prev.queuedJobs.elements,
                ...fetchMoreResult.queuedJobs.elements
              ]
            }
          };
        }
      });
      setPage(page + 1); // Update the current page
    } catch (error) {
      console.error('Error loading more jobs:', error);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (data.queuedJobs.elements.length < data.queuedJobs.metadata.total) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <div className="container-fluid px-0 py-0">
        <div className="autoapply-btn d-flex justify-content-end align-items-middle">
          <div className="my-auto">
            <p className="mb-0 me-2 mt-2 fw-semibold">
              {autoApply ? 'Disable Auto Apply' : 'Enable Auto Apply'}
            </p>
          </div>
          <div className="mt-0">
            <label id="auto_apply_toggle" className="switch">
              <input
                type="checkbox"
                checked={autoApply}
                onChange={handleAutoApply}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <div className="row mb-0">
          <div className="col-4 mt-4 aboutme-input">
            <p className="apply-field-h mb-1">Select your CV/Resume</p>
            <select
              className="py-1 px-2 w-100"
              id="inputGroupSelect04"
              aria-label="Example select with button addon"
              name="selectcv"
              value={selectedCV}
              onChange={(e) => setSelectedCV(e.target.value)}
            >
              <option value="">Select CV/Resume</option>
              {resumeList.map((resume) => (
                <option key={resume.key} value={resume._id}>
                  {resume.filename}
                </option>
              ))}
            </select>
            <p className="small text-black-50 mb-0">
              Check jobs for selected Resume
            </p>
          </div>
          <div className="col-4 mt-4 aboutme-input">
            <p className="apply-field-h mb-1">Select Job Status</p>
            <select
              className="py-1 px-2 w-100"
              id="inputGroupSelect04"
              aria-label="Example select with button addon"
              name="selectcv"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="QUEUED">QUEUED</option>
              <option value="PENDING">PENDING</option>
              <option value="APPLYING">APPLYING</option>
            </select>
          </div>
          <div className="col-4 mt-4 aboutme-input">
            <p className="apply-field-h mb-1">Search</p>
            <input
              className="py-1 px-2 w-100"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <hr />
        {loading ? (
          <LoadingAPI />
        ) : (
          data?.queuedJobs?.elements.map((queuedJob) => (
            <div
              key={queuedJob._id}
              className="matchme-box rounded p-4 border my-2"
            >
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 84 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52.8739 2.72508L44.5864 0.5L37.6025 26.6166L31.2972 3.03836L23.0096 5.26344L29.822 30.738L12.8538 13.7361L6.78692 19.8151L25.3989 38.4644L2.22064 32.2413L0 40.5453L25.3251 47.3447C25.0352 46.0917 24.8818 44.786 24.8818 43.4444C24.8818 33.9484 32.5645 26.2503 42.0416 26.2503C51.5188 26.2503 59.2015 33.9484 59.2015 43.4444C59.2015 44.7773 59.05 46.075 58.7636 47.3207L81.7795 53.5L84 45.196L58.5742 38.3696L81.754 32.1461L79.5332 23.8421L54.1085 30.6681L71.0766 13.6663L65.0097 7.58727L46.6559 25.9778L52.8739 2.72508Z"
                      fill="#F97316"
                    />
                    <path
                      d="M59.1298 47.5C58.4424 50.438 56.9912 53.0774 55 55.192L71.1307 71.5L77 65.5663L59.1298 47.5Z"
                      fill="#F97316"
                    />
                    <path
                      d="M54.4782 55.5C52.4466 57.6137 49.8792 59.1911 47 60.004L52.9174 82.5L61 80.2937L54.4782 55.5Z"
                      fill="#F97316"
                    />
                    <path
                      d="M47 59.5783C45.6243 59.9321 44.1824 60.12 42.6965 60.12C41.1045 60.12 39.563 59.9041 38.0995 59.5L32 82.2692L40.3238 84.5L47 59.5783Z"
                      fill="#F97316"
                    />
                    <path
                      d="M37 59.1446C34.1227 58.2775 31.5635 56.6541 29.5438 54.5L13 71.375L19.0048 77.5L37 59.1446Z"
                      fill="#F97316"
                    />
                    <path
                      d="M30 55.0127C28.013 52.9359 26.5657 50.3606 25.8768 47.5L3 53.4843L5.20003 61.5L30 55.0127Z"
                      fill="#F97316"
                    />
                  </svg>
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="fw-bold mb-0">
                      {queuedJob.job.title} | <i>{queuedJob.job.companyName}</i>
                    </h4>
                    <span className="small text-muted bg-info rounded-1 px-4">
                      {queuedJob.status}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3 align-items-center">
                      <p className="text-primary mt-1 mb-0">
                        You could <strong>save up to 10 minutes </strong>
                        applying to this job using SaveTimeApply
                        <span className="text-danger fs-4">*</span>
                      </p>
                    </div>
                    <div>
                      <p className="my-auto matchtime px-2 pb-1 mb-2">
                        30 minutes ago
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <ul className="d-flex gap-3 list-unstyled fw-semibold">
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        Job Area
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        {queuedJob.job.employmentHourType}
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        {queuedJob.job.employmentType}
                      </li>
                      {/* {savedJob.job?.salary && (
                        <li className="d-flex gap-2 align-items-center">
                          <svg
                            width="5"
                            height="4"
                            viewBox="0 0 5 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                              fill="black"
                            />
                          </svg>
                          {`${savedJob.job?.salary?.currency} - ${savedJob.job?.salary?.minSalary} - ${savedJob.job?.salary?.maxSalary} / ${savedJob.job?.salary?.salaryType}`}
                        </li>
                      )} */}
                    </ul>
                    <div className="px-2 mb-2">{queuedJob.job.location}</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3">
                      <span className="px-2 border">Analytics </span>
                      <span className="px-2 border">Management </span>
                      <span className="px-2 border">Skill Name </span>
                      <span className="px-2 border">Skill Name </span>
                      <span className="px-2 border">Skill Name </span>
                    </div>
                    <div className="d-flex gap-3">
                      {/* <Tooltip
                        anchorSelect="#savejob"
                        content="Remove Job from JobCart"
                      />
                      <button
                        id="savejob"
                        className="my-auto btn-sm-matchMe-active"
                        type="button"
                        // onClick={() => handleRemoveSavedJob(savedJob._id)}
                      >
                        <svg
                          width="20"
                          height="20"
                          fill="#ffffff"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M15.115 11.185v6.97l-5-2.14-5 2.14V5.185h6v-2h-6c-1.1 0-2 .9-2 2v16l7-3 7 3v-10h-2Zm.83-2-2.83-2.83 1.41-1.41 1.41 1.41 3.54-3.54 1.41 1.41-4.94 4.96Z"></path>
                        </svg>
                      </button> */}
                      <Tooltip
                        anchorSelect="#queue"
                        content="Remove job from Queue"
                      />
                      <button
                        id="queue"
                        className="my-auto btn-sm-matchMe-active"
                        type="button"
                        onClick={() => handleRemoveQueuedJob(queuedJob._id)}
                      >
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6Zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm0 14H8V4h12v12Zm-7-1h2v-4h4V9h-4V5h-2v4H9v2h4v4Z"></path>
                        </svg>
                      </button>
                      <button className="btn btn-outline-primary px-4">
                        Strong Match
                      </button>
                      <a
                        className="btn btn-primary px-5"
                        href={queuedJob.job.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apply Now
                      </a>
                      {/* <button className="btn btn-primary px-5">
                      Apply Now
                    </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="mt-4 mb-5 d-flex justify-content-between">
        <div className="d-flex gap-4">
          <p className="my-auto text-muted fw-semibold">
            Total Jobs: {data?.queuedJobs?.metadata?.total}
          </p>
          <p className="my-auto text-muted fw-semibold">Current Page: {page}</p>
        </div>
        <div className="d-flex  gap-4">
          <p className="my-auto text-muted fw-semibold">
            Jobs per Page: {limit}
          </p>
          <button
            className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
            onClick={handlePreviousPage}
            disabled={page === 1}
          >
            Previous Page
          </button>
          <button
            className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
            onClick={handleNextPage}
          >
            Next Page
          </button>
          {/* {data.savedJobs.elements.length >= limit &&
              page < Math.ceil(data.savedJobs.metadata.total / limit) && (
                <button onClick={handleNextPage}>Next Page</button>
              )} */}
          {/* {data.savedJobs.elements.length < data.savedJobs.metadata.total && (
            )} */}
        </div>
      </div>
    </>
  );
};

export default Queued;
