import React, { useMemo, useState } from 'react';
import logo from '../images/SaveTimeApply.png';
import login_img from '../images/LoginImage.svg';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Loading from '../components/loader/Loading';

import { SocialAnalyticsApiUrl } from '../config/URL';

const getUserAgentData = () => {
  if (navigator.userAgentData) {
    return {
      brands: navigator.userAgentData.brands,
      mobile: navigator.userAgentData.mobile,
      platform: navigator.platform
    };
  }

  return null;
};

function PreLogin() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const userAgentData = useMemo(getUserAgentData, []);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await axios({
        method: 'post',
        url: SocialAnalyticsApiUrl,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          event: 'hear_about_us',
          source: data.Social,
          useragent: userAgentData
        }
      });
      console.log('PreLogin Request Data:' + response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      navigate('/login');
    }
    console.log(`Where did you hear about us? Data: ${data}`);
  };

  return (
    <>
      {loading && <Loading />}
      <div className="backgroundImageOpacity login-main-bg py-4">
        <div className="login-overlay-position"></div>
        <div className="d-flex justify-content-center mt-5">
          <div className="login-card">
            <div>
              <img width={200} height={78} alt="login" src={logo} />
            </div>
            <div className="my-3">
              <img width={200} height={200} alt="login" src={login_img} />
            </div>
            <p className="mb-1">
              Where did you hear about us?{' '}
              {errors.Social && <span className="text-danger">*</span>}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <select
                className="form-select form-select-lg mb-0 mx-2"
                {...register('Social', {
                  required: { value: true, message: 'Select Option to proceed' }
                })}
                aria-label="form-select-lg example"
              >
                <option value="">Select option</option>
                {['LinkedIn', 'Twitter', 'Facebook', 'Instagram'].map(
                  (item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  )
                )}
              </select>

              <button
                className="text-center mt-4 btn btn-primary px-5"
                type="submit"
              >
                Get Started
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreLogin;
