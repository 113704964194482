import { useQuery } from '@apollo/client';
import {
  GET_CURRENT_USER_PLAN,
  AUTO_APPLY_STATUS,
  RESUME_PARSER_QUERY
} from './queries';

export const useCurrentPlan = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER_PLAN, {
    fetchPolicy: 'no-cache'
  });

  if (loading) return { loading };
  if (error) return { error };

  return { data: data.getCurrentUserPlan };
};

export const AutoApplyStatus = () => {
  const { loading, error, data, refetch } = useQuery(AUTO_APPLY_STATUS, {
    fetchPolicy: 'no-cache'
  });

  if (loading) return { loading };
  if (error) return { error };

  return { data: data.isAutoApplyPaused, refetch };
};

export const useParseResume = (resumeId) => {
  const { loading, error, data } = useQuery(RESUME_PARSER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { resumeId },
    skip: !resumeId
  });
  const resumeData = data ? JSON.parse(data.parseResume) : null;
  return {
    loading,
    error,
    resumeData
  };
};
