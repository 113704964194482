import Cookies from 'universal-cookie';

const jar = new Cookies();

export const removeCookies = () => {
  const cookies = jar.getAll();
  Object.keys(cookies).forEach((cookieName) => {
    jar.remove(cookieName);
  });
};

export default jar;
