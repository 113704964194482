import React from 'react';
import './loading.css';
import loading from '../../images/SaveTimeApply.gif';

export default function Loading() {
  return (
    <div className="loadingPage">
      <div className="loadingDiv">
        <img src={loading} alt="Loading" />
      </div>
    </div>
  );
}
