import React from 'react';
import './LoadingAPI.css';
import loading from '../../images/SaveTimeApply.gif';

export default function LoadingAPI() {
  return (
    <div className="loadingAPI">
      <div className="loadingDiv">
        <img src={loading} alt="Loading" />
      </div>
    </div>
  );
}
