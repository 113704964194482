import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import client from './appolo/apolloClient';

import Router from './Router';
// import * as Sentry from '@sentry/react';

// Sentry.init({
//   dsn: 'https://d93dbf18b912684bce224fb6d6f0282a@o4507729733550080.ingest.us.sentry.io/4507729737416704',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'localhost',
//         /^https:\/\/yourserver\.io\/api/,
//         /^https:\/\/dashboard\.savetimeapply\.info\//,
//         /^https:\/\/dashboard\.savetimeapply\.com\//
//       ]
//       // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//       //   React.useEffect,
//       //   useLocation,
//       //   useNavigationType,
//       //   createRoutesFromChildren,
//       //   matchRoutes
//       // ),
//     }),
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: true,
//       blockAllMedia: true
//     })
//   ],

//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
    <ToastContainer
      position={toast.POSITION.TOP_CENTER}
      toastStyle={{ backgroundColor: '#4662C1' }}
      autoClose={1000}
    />
  </React.StrictMode>
);
