import React from 'react';

const Applications = () => {
  return (
    <>
      <h3>Track</h3>
      <div className="table-responsive bg-white rounded">
        <table className="table align-middle">
          <thead>
            <tr>
              <th>Title</th>
              {/* <th>Location</th> */}
              <th>Company</th>
              <th>Job Status</th>
              <th>Application</th>
              <th>Applied On</th>
              <th>Job Link</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {/* {filteredAndSortedJobs.map((job) => ( */}
            <tr
            //  key={job._id}
            >
              <td className="text-capitalize">Junior UX Designer</td>
              <td className="text-capitalize">Company Name</td>
              <td className="text-capitalize">
                <span>Applied</span>
              </td>
              <td className="text-capitalize">
                <span>Application</span>
              </td>
              <td className="text-capitalize">23 March 2024</td>
              <td className="text-capitalize">
                {/* {formatDateTime(job.applied_on)} */}
              </td>
              <td>
                <a
                  className="session-link"
                  //   href={`${job.url}`}
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Job URL
                </a>
              </td>
            </tr>
            {/* ))} */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Applications;
