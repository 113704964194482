import React, { useState, useEffect } from 'react';

const Quotes = () => {
  // List of quotes
  const quotes = [
    {
      text: 'Success is not final, failure is not fatal: It is the courage to continue that counts.',
      author: 'Winston Churchill'
    },
    {
      text: 'The only way to do great work is to love what you do. If you haven`t found it yet, keep looking. Don`t settle.',
      author: 'Steve Jobs'
    },
    {
      text: 'The road to success and the road to failure are almost exactly the same.',
      author: 'Colin R. Davis'
    },
    {
      text: 'Believe you can and you`re halfway there.',
      author: 'Theodore Roosevelt'
    },
    {
      text: 'The future depends on what you do today.',
      author: 'Mahatma Gandhi'
    },
    {
      text: 'Opportunities don`t happen. You create them.',
      author: 'Chris Grosser'
    },
    {
      text: 'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.',
      author: 'Albert Schweitzer'
    },
    {
      text: 'The only limit to our realization of tomorrow will be our doubts of today.',
      author: 'Franklin D. Roosevelt'
    },
    {
      text: 'The harder you work for something, the greater you`ll feel when you achieve it.',
      author: 'Unknown'
    },
    {
      text: 'The only way to achieve the impossible is to believe it is possible.',
      author: 'Charles Kingsleigh (Alice in Wonderland)'
    },
    {
      text: 'Success is not in what you have, but who you are.',
      author: 'Bo Bennett'
    },
    {
      text: 'Your attitude, not your aptitude, will determine your altitude.',
      author: 'Zig Ziglar'
    },
    {
      text: 'Don`t wait for opportunity. Create it.',
      author: 'Unknown'
    },
    {
      text: 'The secret of getting ahead is getting started.',
      author: 'Mark Twain'
    },
    {
      text: 'Success is walking from failure to failure with no loss of enthusiasm.',
      author: 'Winston Churchill'
    },
    {
      text: 'The only place where success comes before work is in the dictionary.',
      author: 'Vidal Sassoon'
    },
    {
      text: 'When one door of happiness closes, another opens, but often we look so long at the closed door that we do not see the one that has been opened for us.',
      author: 'Helen Keller'
    },
    {
      text: 'Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.',
      author: 'Christian D. Larson'
    },
    {
      text: 'Success doesn`t come from what you do occasionally. It comes from what you do consistently.',
      author: 'Marie Forleo'
    },
    {
      text: 'You miss 100% of the shots you don`t take.',
      author: 'Wayne Gretzky'
    },
    {
      text: 'If opportunity doesn`t knock, build a door.',
      author: 'Milton Berle'
    },
    {
      text: 'The only limit to our realization of tomorrow will be our doubts of today.',
      author: 'Franklin D. Roosevelt'
    },
    {
      text: 'Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.',
      author: 'Steve Jobs'
    },
    {
      text: 'Success is not in what you have, but who you are.',
      author: 'Bo Bennett'
    },
    {
      text: 'Your attitude, not your aptitude, will determine your altitude.',
      author: 'Zig Ziglar'
    },
    {
      text: 'Don`t wait for opportunity. Create it.',
      author: 'Unknown'
    },
    {
      text: 'The secret of getting ahead is getting started.',
      author: 'Mark Twain'
    },
    {
      text: 'Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.',
      author: 'Steve Jobs'
    },
    {
      text: 'Success is walking from failure to failure with no loss of enthusiasm.',
      author: 'Winston Churchill'
    },
    {
      text: 'The only place where success comes before work is in the dictionary.',
      author: 'Vidal Sassoon'
    },
    {
      text: 'When one door of happiness closes, another opens, but often we look so long at the closed door that we do not see the one that has been opened for us.',
      author: 'Helen Keller'
    },
    {
      text: 'Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.',
      author: 'Christian D. Larson'
    },
    {
      text: 'Success doesn`t come from what you do occasionally. It comes from what you do consistently.',
      author: 'Marie Forleo'
    },
    {
      text: 'You miss 100% of the shots you don`t take.',
      author: 'Wayne Gretzky'
    },
    {
      text: 'If opportunity doesn`t knock, build a door.',
      author: 'Milton Berle'
    },
    {
      text: 'The only limit to our realization of tomorrow will be our doubts of today.',
      author: 'Franklin D. Roosevelt'
    }
  ];

  // State to hold the current quote index
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  // Function to update the current quote
  const updateQuote = () => {
    // setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    setCurrentQuoteIndex(Math.floor(Math.random() * quotes.length));
  };

  // Effect to update the quote every 5 minutes
  useEffect(() => {
    const timer = setInterval(updateQuote, 30 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div className="quotes text-center p-5 my-4">
        <h4 className="fw-semibold">{quotes[currentQuoteIndex].text}</h4>
        <p className="fw-bold mb-0 fst-italic">
          - {quotes[currentQuoteIndex].author}
        </p>
      </div>
    </>
  );
};

export default Quotes;
