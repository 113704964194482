import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
// import { RemoveFromQueue } from "@mui/icons-material";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  p: 4,
  background: 'black',
  borderRadius: '16px',
  border: 'none'
};

RatingModal.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default function RatingModal({ setOpen, open }) {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const navigate = useNavigate();

  const handleReviewSubmit = () => {
    // handle review submission logic here
    setOpen(false); // close the modal after submitting the review
    history.push('/dashboard');
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Box
          sx={{
            ...style,
            width: 600,
            background: 'white',
            border: 'none',
            outline: 'none'
          }}
        >
          <from
            className="text-center"
            onSubmit={(event) => {
              event.preventDefault();
              const review = event.target.elements.review.value;
              handleReviewSubmit(review);
            }}
          >
            <div>
              <p className="rating-modal-heading">Rate Your Experience</p>
            </div>
            <div className="my-3">
              <Rating
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                sx={{
                  fontSize: '70px'
                }}
              />
            </div>
            <div className="rating-modal-text-field">
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="Type Here...."
              />
            </div>
            <div>
              <button
                className="py-2 rating-modal-submit-btn"
                type="submit"
                onClick={() => navigate('/dashboard')}
              >
                Submit
              </button>
            </div>
          </from>
        </Box>
      </Modal>
    </div>
  );
}
