import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import jobCartIcon from '../../images/cart.svg';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SEARCH_JOBS_QUERY } from '../../GraphQL/queries';
import {
  SAVE_JOBS_MUTATION,
  QUEUE_JOB_MUTATION
} from '../../GraphQL/mutations';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const validationSchema = yup.object({
  job_title: yup.string().required('Job Title is required'),
  countries_companies: yup.string().optional(),
  cities_companies: yup.string().optional(),
  exclude_companies: yup.string().optional()
});

const SearchJobs = () => {
  const { resumeList } = useUser();
  const [getSearchJobs, { loading, error, data }] = useLazyQuery(
    SEARCH_JOBS_QUERY,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const [saveJobMutation] = useMutation(SAVE_JOBS_MUTATION);
  const [queueJobMutation] = useMutation(QUEUE_JOB_MUTATION);
  const [savedJobs, setSavedJobs] = useState([]);
  const [selectedCV, setSelectedCV] = useState();

  // console.log('Query Data:', data);
  // console.log('GraphQL Query Error:', error?.message || error);

  // Validation Begin Here
  const {
    control,
    handleSubmit,
    // formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      job_title: '',
      countries_companies: '',
      cities_companies: '',
      exclude_companies: ''
    }
  });

  const onSubmit = (data) => {
    const {
      job_title,
      countries_companies,
      cities_companies,
      exclude_companies
    } = data;
    const variables = {
      searchJobsInput: {
        title: job_title,
        countryCode: countries_companies,
        city: cities_companies,
        companiesToExclude: exclude_companies
      }
    };
    // Filter out properties with empty strings or undefined values
    Object.keys(variables.searchJobsInput).forEach((key) => {
      if (!variables.searchJobsInput[key]) {
        delete variables.searchJobsInput[key];
      }
    });

    getSearchJobs({ variables });
    // console.log('Variables', { variables });
  };

  //   HANDLE SAVE JOBS
  const handleSaveJob = async (jobId) => {
    try {
      await saveJobMutation({
        variables: {
          saveJobsInput: {
            jobIds: jobId
          }
        }
      });
      console.log('Job saved successfully!');
      toast.success('Job saved successfully!', toastConfig);
      setSavedJobs([...savedJobs, data.saveJobs]);
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  // HANDLE QUEUE JOBS
  const handleQueueJob = async (jobId) => {
    try {
      await queueJobMutation({
        variables: {
          queueJobsInput: {
            jobIds: jobId,
            resumeId: selectedCV
          }
        }
      });
      console.log('Job saved successfully!');
      toast.success('Job added to Queue successfully!', toastConfig);
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  return (
    <>
      <div className="container-fluid px-0 mb-5">
        <form id="job_search_form" onSubmit={handleSubmit(onSubmit)}>
          {/* Other Information */}
          <div className="py-4 mb-2">
            <h3>Fill in the details to start your match me search process</h3>
            <div className="row pt-2">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Job Title</p>
                  <Controller
                    name="job_title"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          className="py-1 px-2 w-100"
                          type="text"
                          placeholder="e.g Product Designer"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                        <p className="small text-black-50">
                          Type only one job title at a time like Software
                          Developer or Data Analyst
                        </p>
                        <p className="text-danger small">
                          {errors.job_title?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Countries To Search Companies For</p>
                  <Controller
                    name="countries_companies"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        >
                          <option value="">Select Country</option>
                          <option value="AUSTRALIA">Australia</option>
                          <option value="BRAZIL">Brazil</option>
                          <option value="CANADA">Canada</option>
                          <option value="FRANCE">France</option>
                          <option value="NEW_ZEALAND">New Zealand</option>
                          <option value="SOUTH_AFRICA">South Africa</option>
                          <option value="UNITED_KINGDOM">United Kingdom</option>
                          <option value="UNITED_STATES">United States</option>
                        </select>
                        <p className="text-danger small">
                          {errors.countries_companies?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Cities To Search Companies For</p>
                  <Controller
                    name="cities_companies"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          className="py-1 px-2 w-100"
                          type="text"
                          placeholder="City name"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                        <p className="text-danger small">
                          {errors.cities_companies?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Companies To Exclude</p>
                  <Controller
                    name="exclude_companies"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          className="py-1 px-2 w-100"
                          type="text"
                          placeholder="e.g Companies to exclude"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                        <p className="text-danger small">
                          {errors.exclude_companies?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-primary px-5" type="submit">
            {/* Start Match Me With Jobs */}
            {loading ? 'Searching...' : 'Find Jobs'}
          </button>
        </form>
        {data?.searchJobs.length > 0 && (
          <div className="col-12 mt-4 aboutme-input">
            <p className="apply-field-h mb-1">
              Select CV/Resume from drop-down to apply jobs shown below
            </p>
            <select
              className="py-1 px-2 w-100"
              id="inputGroupSelect04"
              aria-label="Example select with button addon"
              name="selectcv"
              value={selectedCV}
              onChange={(e) => setSelectedCV(e.target.value)}
            >
              <option value="">Select CV/Resume</option>
              {resumeList.map((resume) => (
                <option key={resume.key} value={resume._id}>
                  {resume.filename}
                </option>
              ))}
            </select>
            <p className="small text-black-50">
              Resume/CV selection is used to save your job for selected Resume
            </p>
          </div>
        )}
        <hr />
        {data &&
          data?.searchJobs &&
          data?.searchJobs.map((job) => (
            <div key={job._id} className="matchme-box rounded p-4 border my-2">
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 84 85"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52.8739 2.72508L44.5864 0.5L37.6025 26.6166L31.2972 3.03836L23.0096 5.26344L29.822 30.738L12.8538 13.7361L6.78692 19.8151L25.3989 38.4644L2.22064 32.2413L0 40.5453L25.3251 47.3447C25.0352 46.0917 24.8818 44.786 24.8818 43.4444C24.8818 33.9484 32.5645 26.2503 42.0416 26.2503C51.5188 26.2503 59.2015 33.9484 59.2015 43.4444C59.2015 44.7773 59.05 46.075 58.7636 47.3207L81.7795 53.5L84 45.196L58.5742 38.3696L81.754 32.1461L79.5332 23.8421L54.1085 30.6681L71.0766 13.6663L65.0097 7.58727L46.6559 25.9778L52.8739 2.72508Z"
                      fill="#F97316"
                    />
                    <path
                      d="M59.1298 47.5C58.4424 50.438 56.9912 53.0774 55 55.192L71.1307 71.5L77 65.5663L59.1298 47.5Z"
                      fill="#F97316"
                    />
                    <path
                      d="M54.4782 55.5C52.4466 57.6137 49.8792 59.1911 47 60.004L52.9174 82.5L61 80.2937L54.4782 55.5Z"
                      fill="#F97316"
                    />
                    <path
                      d="M47 59.5783C45.6243 59.9321 44.1824 60.12 42.6965 60.12C41.1045 60.12 39.563 59.9041 38.0995 59.5L32 82.2692L40.3238 84.5L47 59.5783Z"
                      fill="#F97316"
                    />
                    <path
                      d="M37 59.1446C34.1227 58.2775 31.5635 56.6541 29.5438 54.5L13 71.375L19.0048 77.5L37 59.1446Z"
                      fill="#F97316"
                    />
                    <path
                      d="M30 55.0127C28.013 52.9359 26.5657 50.3606 25.8768 47.5L3 53.4843L5.20003 61.5L30 55.0127Z"
                      fill="#F97316"
                    />
                  </svg>
                </div>
                <div className="w-100">
                  <h4 className="fw-bold mb-0">{job.title}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3 align-items-center">
                      <p className="text-primary mt-1 mb-0">
                        You could <strong>save up to 10 minutes </strong>
                        applying to this job using SaveTimeApply
                        <span className="text-danger fs-4">*</span>
                      </p>
                    </div>
                    <div>
                      <p className="my-auto matchtime px-2 pb-1 mb-2">
                        30 minutes ago
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <ul className="d-flex gap-3 list-unstyled fw-semibold">
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        Job Area
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        {job.employmentHourType}
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        {job.employmentType}
                      </li>
                      {job?.salary && (
                        <li className="d-flex gap-2 align-items-center">
                          <svg
                            width="5"
                            height="4"
                            viewBox="0 0 5 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                              fill="black"
                            />
                          </svg>
                          {`${job?.salary?.currency} - ${job?.salary?.minSalary} - ${job?.salary?.maxSalary} / ${job?.salary?.salaryType}`}
                        </li>
                      )}
                    </ul>
                    <div className="px-2 mb-2">{job.location}</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3">
                      <span className="px-2 border">Analytics </span>
                      <span className="px-2 border">Management </span>
                      <span className="px-2 border">Skill Name </span>
                      <span className="px-2 border">Skill Name </span>
                      <span className="px-2 border">Skill Name </span>
                    </div>
                    <div className="d-flex gap-3">
                      <Tooltip
                        anchorSelect="#savejob"
                        content="Add to JobCart"
                      />
                      <button
                        id="savejob"
                        className="my-auto btn-sm-jobcart"
                        type="button"
                        onClick={() => handleSaveJob(job._id)}
                      >
                        <img src={jobCartIcon} alt="Job cart Icon" />
                      </button>
                      <Tooltip
                        anchorSelect="#queue"
                        content={
                          !selectedCV
                            ? 'Select CV first to queue job'
                            : 'Add to Queued Jobs'
                        }
                      />
                      <button
                        id="queue"
                        className="my-auto btn-sm-matchMe"
                        type="button"
                        onClick={() => handleQueueJob(job._id)}
                        disabled={!selectedCV ? true : false}
                      >
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6Zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm0 14H8V4h12v12Zm-7-1h2v-4h4V9h-4V5h-2v4H9v2h4v4Z"></path>
                        </svg>
                      </button>
                      <button className="btn btn-outline-primary px-4">
                        Strong Match
                      </button>
                      <a
                        className="btn btn-primary px-5"
                        href={job.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SearchJobs;
