import React from 'react';
import logo from '../images/SaveTimeApply.png';
import login_img from '../images/LoginImage.svg';
import google_icon from '../images/googleIcon.png';

import { URL } from '../config/URL';

function Login() {
  return (
    <div className="backgroundImageOpacity login-main-bg">
      <div className="login-overlay-position"></div>
      <div className="d-flex justify-content-center mt-5 pt-4">
        <div className="login-card">
          <div>
            <img width={200} height={78} alt="login" src={logo} />
          </div>
          <div className="my-3">
            <img width={200} height={200} alt="login" src={login_img} />
          </div>
          <div className="mb-2">
            <a className="px-5 google-login-btn" href={`${URL}/auth/google`}>
              <img src={google_icon} width={25} height={25} alt="google" />
              <p className="my-auto">Sign in with Google</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
