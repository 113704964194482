import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jar from '../helpers/cookies';
import axios from 'axios';
import { URL, ParserUrl } from '../config/URL';
// import { toast } from 'react-toastify';

// const toastConfig = {
//   position: 'top-center',
//   autoClose: 5000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
//   theme: 'colored'
// };

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  // const [userEmails, setUserEmails] = useState([]);
  const [appliedJobsData, setAppliedJobsData] = useState(null);
  const [platformInfo, setPlatformInfo] = useState({});
  const [resumeList, setResumeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numApply, setNumApply] = useState(() => {
    return localStorage.getItem('numApply') || '';
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [countriesAll, setCountriesAll] = useState([]);

  const bearerToken = jar.get('token');

  const GetResumeListAPI = `${URL}/resume/list`;
  const JobsApiUrl = `${URL}/job/stats/list`;
  const DeleteResumeAPI = `${URL}/resume`;
  const ProfileApiUrl = `${URL}/user/profile`;
  // const emails = `${URL}/user/emails`;

  // const fetchUserEmails = async () => {
  //   try {
  //     const response = await axios.get(emails, {
  //       headers: {
  //         Authorization: `Bearer ${bearerToken}`
  //       }
  //     });

  //     // Assuming the API response has a structure similar to other responses in your code
  //     setUserEmails(response.data.data);
  //     toast.success(response.data.msg, toastConfig);
  //   } catch (error) {
  //     console.error('Error fetching user emails:', error);
  //     toast.error(error.message, toastConfig);
  //   }
  // };

  // Resume List
  const fetchResumeList = async () => {
    try {
      const response = await axios.get(GetResumeListAPI, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });

      setResumeList(response.data.data);
    } catch (error) {
      console.error('Error fetching resume list:', error);
    }
  };

  // Delete Resume
  const deleteResume = async (_id) => {
    try {
      const response = await axios.delete(`${DeleteResumeAPI}/${_id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });

      setResumeList((prevResumeList) =>
        prevResumeList.filter((resume) => resume._id !== _id)
      );

      return response.data.msg;
    } catch (error) {
      console.error('Error deleting resume:', error);
      throw new Error('File deletion failed!');
    }
  };

  // Upload Resume
  const uploadResume = async (file, bearerToken) => {
    const data = new FormData();
    data.append('resume', file);

    return axios.post(`${URL}/resume/upload`, data, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  // Upload Resume Handler
  const handleUploadResume = async (file, bearerToken) => {
    try {
      const response = await uploadResume(file, bearerToken);
      await fetchResumeList();
      return response.data;
    } catch (error) {
      console.error('Upload Resume Error--:', error);
      console.error('Upload Resume Error:', error.message, error.response);
      throw error;
    }
  };

  // Get Resume Data (Resume Parser)
  const getResumeData = async (resumeKey) => {
    return axios.get(`${ParserUrl}/get_resume?resume_object_key=${resumeKey}`);
  };

  // Handle Get Resume Data API call
  const handleGetResumeData = async (resumeKey) => {
    try {
      const response = await getResumeData(resumeKey);
      return response.data.data;
    } catch (error) {
      console.error('Get Resume Data Error:', error);
      throw error;
    }
  };

  // Track Job Platforms Info
  const fetchPlatformInfo = async (bearerToken) => {
    try {
      const response = await axios.get(JobsApiUrl, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      });

      const totalAppliedJobsUpdate = {
        linkedin: 0,
        indeed: 0,
        ziprecruiter: 0,
        glassdoor: 0,
        dice: 0
      };

      const lastAppliedDatesUpdate = {
        linkedin: null,
        indeed: null,
        ziprecruiter: null,
        glassdoor: null,
        dice: null
      };

      for (const job of response.data.data) {
        if (job.status === 'completed') {
          totalAppliedJobsUpdate[job.portal]++;
          const lastAppliedDate = new Date(job.applied_on);
          const formattedDate = lastAppliedDate.toLocaleDateString('en-GB');
          lastAppliedDatesUpdate[job.portal] = formattedDate;
        }
      }

      setPlatformInfo({
        totalAppliedJobs: totalAppliedJobsUpdate,
        lastAppliedDates: lastAppliedDatesUpdate
      });

      setLoading(false);
    } catch (error) {
      console.error('Error fetching platform info:', error);
      setLoading(false);
    }
  };

  // Platforms Jobs
  const fetchJobs = (filterCriteria, setJobs) => {
    const { portal, status } = filterCriteria;

    if (portal && status) {
      const JobsApiUrlFiltered = `${JobsApiUrl}?portal=${portal}&status=${status}`;

      const config = {
        method: 'get',
        url: JobsApiUrlFiltered,
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      };

      axios
        .request(config)
        .then((response) => {
          setJobs(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Commom UseEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userDataResponse, AppliedJobsDataResponse] = await Promise.all([
          axios.get(ProfileApiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`
            }
          }),
          axios.get(JobsApiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`
            }
          })
        ]);

        setUserProfile(userDataResponse.data.data);
        setAppliedJobsData(AppliedJobsDataResponse.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
    fetchResumeList();

    // fetchUserEmails();
    localStorage.setItem('numApply', numApply);

    if (bearerToken) {
      fetchPlatformInfo(bearerToken);
    }

    // Countries

    const desiredCountries = [
      'United Kingdom',
      'United States',
      'Canada',
      'New Zealand',
      'South Africa',
      'Australia',
      'France',
      'Brazil'
    ];

    axios
      .get('https://countriesnow.space/api/v0.1/countries/')
      .then((response) => {
        const countryList = response.data.data.map((country) => ({
          name: country.country
        }));
        const filtered = countryList.filter((country) =>
          desiredCountries.includes(country.name)
        );
        setCountriesAll(countryList);
        setCountries(filtered);
      })
      .catch((error) => console.error(error));
  }, [bearerToken, numApply]);

  // Fetch Cities
  const fetchCities = (selectedCountry) => {
    setLoadingCities(true);
    const data = JSON.stringify({
      country: selectedCountry
    });

    axios
      .post('https://countriesnow.space/api/v0.1/countries/cities', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        setCities(response.data.data);
        setLoadingCities(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingCities(false);
      });
  };

  return (
    <UserContext.Provider
      value={{
        userProfile,
        appliedJobsData,
        platformInfo,
        fetchJobs,
        resumeList,
        loading,
        fetchResumeList,
        deleteResume,
        handleUploadResume,
        handleGetResumeData,
        numApply, // Include numJobApply in the context value
        setNumApply, // Function to update numJobApply
        // userEmails
        countries,
        countriesAll,
        cities,
        loadingCities,
        fetchCities
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useUser = () => {
  return useContext(UserContext);
};
