import React from 'react';
import { Link } from 'react-router-dom';
import img404 from '../images/404.svg';

const PageNotFound = () => {
  return (
    <div className="container d-flex align-items-center min-vh-100 w-100 text-center">
      <div className="my-auto mx-auto">
        <div className="d-flex align-items-center justify-content-center">
          <img src={img404} alt="404 Image" className="img-fluid" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-center mt-4">
            <h1 className="fw-bold">Uh-oh!</h1>
            <h4 className="w-lg-60 w-md-90 mb-4 mx-auto">
              You were not meant to see this. Click below to go back home.
            </h4>
            <Link to="/dashboard" className="btn btn-primary px-5">
              Go back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
