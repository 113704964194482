import React from 'react';
import AppBarDrawer from '../components/layout/AppBarDrawer';
import JobHistory from '../components/matchMe/JobHistory';

function TrackJob() {
  return (
    <div>
      <AppBarDrawer>
        <div className="mt-2 py-2 ps-3 d-flex justify-content-between">
          <h4 className="text-start ps-4">My Job Diary</h4>
        </div>
        <div className="px-4 py-1 trackjob-main">
          <JobHistory />
        </div>
      </AppBarDrawer>
    </div>
  );
}

export default TrackJob;
