import React, { useState } from 'react';
import AppBarDrawer from '../../components/layout/AppBarDrawer';
import SearchJobs from '../../components/matchMe/SearchJobs';
// import Preferences from '../../components/matchMe/Preferences';
// import Recommended from '../../components/matchMe/Recommended';
import Queued from '../../components/matchMe/Queued';
import Applications from '../../components/matchMe/Applications';
import JobCart from '../../components/matchMe/JobCart';
// import JobHistory from '../../components/matchMe/JobHistory';

const MatchMe = () => {
  // Active Tab
  const [activeTab, setActiveTab] = useState('Search Jobs');

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <AppBarDrawer>
        <div className="container p-4">
          <div className="tab-buttons border-bottom py-2">
            {[
              'Search Jobs',
              // 'Preferences',
              // 'Recommended',
              'My JobCart',
              'Up-Next Queue'
              // 'Job History'
              // 'Applications'
            ].map((btnName) => (
              <button
                key={btnName}
                className={`btn p-0 pe-5 text-capitalize fw-semibold border-0 fs-6 ${
                  activeTab === btnName ? 'text-primary' : ''
                }`}
                onClick={() => handleTabClick(btnName)}
              >
                {btnName}
              </button>
            ))}
          </div>
          <div className="tab-content py-2">
            {activeTab === 'Search Jobs' && <SearchJobs />}
            {/* {activeTab === 'Preferences' && <Preferences />} */}
            {/* {activeTab === 'Recommended' && <Recommended />} */}
            {activeTab === 'My JobCart' && <JobCart />}
            {activeTab === 'Up-Next Queue' && <Queued />}
            {/* {activeTab === 'Job History' && <JobHistory />} */}
            {activeTab === 'Applications' && <Applications />}
          </div>
        </div>
      </AppBarDrawer>
    </>
  );
};
export default MatchMe;
