import React, { useState, useEffect } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { useUser } from '../../context/UserContext';
import vectors from '../../images/vectors-arrow.png';

const SpeedoMeter = () => {
  const { userProfile, numApply } = useUser();
  const [currentPlan, setCurrentPlan] = useState([]);
  const [speedometerKey, setSpeedometerKey] = useState(0);
  const [manualTimePerJobMinutes] = useState(5); // Time for manual application in minutes
  const [animatedTime, setAnimatedTime] = useState(0);

  useEffect(() => {
    if (userProfile) {
      const { activation } = userProfile;
      const maxVal = activation?.daily_apply_limit.tokens;
      const planName = activation.title;

      const customSegmentStops = Array.from({ length: 11 }, (_, i) =>
        Math.floor((i / 10) * maxVal)
      );

      const dynamicPlans = [
        {
          name: planName,
          maxValue: maxVal,
          customSegmentStops
        },
        {
          name: planName,
          maxValue: maxVal,
          customSegmentStops
        },
        {
          name: planName,
          maxValue: maxVal * 2,
          customSegmentStops
        },
        {
          name: planName,
          maxValue: maxVal * 3,
          customSegmentStops
        }
      ];

      setCurrentPlan(dynamicPlans[0]);
      setSpeedometerKey(speedometerKey + 1);
    }

    // total time taken by automation
    const automationTimePerJobInSeconds = 15; // Time for automated application in seconds
    const totalAutomationTimeInSeconds =
      numApply * automationTimePerJobInSeconds;
    console.log('totalAutomationTimeInSeconds', totalAutomationTimeInSeconds);

    // Calculate time saved
    const totalManualTimeInSeconds = numApply * manualTimePerJobMinutes * 60;
    console.log('totalManualTimeInSeconds', totalManualTimeInSeconds);
    const timeSavedInSeconds =
      totalManualTimeInSeconds - totalAutomationTimeInSeconds;
    console.log('timeSavedInSeconds', timeSavedInSeconds);

    const animationDuration = 2000;
    let startTime;

    const animateTime = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / animationDuration;

      // Update the animated time gradually
      setAnimatedTime(
        Math.min(timeSavedInSeconds * progress, timeSavedInSeconds)
      );

      if (progress < 1) {
        requestAnimationFrame(animateTime);
      }
    };

    requestAnimationFrame(animateTime);
  }, [userProfile, numApply, manualTimePerJobMinutes]);

  const hours = Math.floor(animatedTime / 3600);
  const minutes = Math.floor((animatedTime % 3600) / 60);
  // const seconds = Math.floor(animatedTime % 60);

  return (
    <>
      <div className="center w-100">
        <div className="bg-vectors">
          <img src={vectors} alt="Arrows Vector" />
        </div>
        <h5 className="text-center mt-4 mb-3">
          Great Stuff: Today you saved
          <span className="px-2 fw-bold saved-time">
            {hours}h : {minutes}m
          </span>
          Your Job Search - Go You! <br /> That means more time for you to do
          the things you love!
        </h5>
        <div className="container-fluid p-3">
          <div className="row">
            <div className="col-8 mx-auto text-center mt-3">
              <div className="speedometer">
                <ReactSpeedometer
                  key={speedometerKey}
                  maxValue={currentPlan.maxValue}
                  ringWidth={15}
                  customSegmentStops={currentPlan.customSegmentStops}
                  segmentColors={[
                    '#B5C0D8',
                    '#B5C0D8',
                    '#B5C0D8',
                    '#6B81B0',
                    '#6B81B0',
                    '#6B81B0',
                    '#6B81B0',
                    '#162C5B',
                    '#162C5B',
                    '#162C5B',
                    '#162C5B',
                    '#162C5B'
                  ]}
                  needleTransitionDuration={2000}
                  // needleTransition="easeQuadInOut:7000"
                  needleHeightRatio={0.7}
                  // segments={1000}
                  // needleSharp={false}
                  needleWidthRatio={0.5}
                  // needleTransition="easeElastic"
                  currentValueText={`${
                    numApply !== null ? numApply : ''
                  } Jobs applied!`}
                  value={numApply !== null ? numApply : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeedoMeter;
