// // apolloClient.js

// // Create an HTTP link
// const httpLink = new httpLink({
//   uri: 'https://your-graphql-api.com/graphql'
// });

// // const restLink = new RestLink({
// //   uri: 'https://your-rest-api.com/'
// // });

import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
  HttpLink,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { persistCache } from 'apollo3-cache-persist';
import { AuthLink } from './AuthLink';
import jar from '../helpers/cookies';
import { URL } from '../config/URL';

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors?.map(({ message, location, path }) => {
      console.log(`Graphql error msg ${message}`);
      console.log(`Graphql error location ${location}`);
      console.log(`Graphql error path ${path}`);
    });
  }
  if (networkError) {
    graphqlErrors?.map(({ message, location, path }) => {
      console.log(`network error msg ${message}`);
      console.log(`network error location ${location}`);
      console.log(`network error path ${path}`);
    });
  }
});

const link = from([errorLink, new HttpLink({ uri: `${URL}/graphql` })]);

// const httpLink = createHttpLink({
//   uri: `${URL}/graphql`
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  const token = jar.get('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

// const cache = new InMemoryCache();

// async function initializeCache() {
//   try {
//     await persistCache({
//       cache,
//       storage: window.localStorage // Use window.localStorage instead of localStorage
//     });
//   } catch (error) {
//     console.error('Error initializing cache:', error);
//   }
// }

// initializeCache();

const cache = new InMemoryCache();

// Initialize the cache persistor with session storage
const initializeCache = async () => {
  try {
    await persistCache({
      cache,
      storage: window.sessionStorage
    });
  } catch (error) {
    console.error('Error initializing cache:', error);
  }
};

// Call initializeCache to start persisting the cache
initializeCache();

const client = new ApolloClient({
  cache: cache,
  link: AuthLink.concat(authLink).concat(link)
});

export default client;
