import React from 'react';
import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Route
} from 'react-router-dom';

import PreLogin from './pages/PreLogin';
import Login from './pages/Login';
import Onboarding from './pages/Onboarding';
import Home from './pages/Home';
import RateJobExp from './pages/RateJobExp';
import EditResume from './pages/updateResume/EditResume';
import EditResume_old from './pages/updateResume/EditResume_old';
import UpdateResume from './pages/updateResume/UpdateResume';
import TrackJob from './pages/TrackJob';
import PricingPlan from './pages/PricingPlan';
import RequireAuth from './utilities/RequireAuth';
import AuthCallback from './utilities/AuthCallback';
import PageNotFound from './pages/PageNotFound';
import CheckoutFailed from './components/CheckoutFailed';
import CheckoutSuccess from './components/CheckoutSuccess';
import LoginSuccess from './components/successConfetties/LoginSuccess';
import { UserProvider } from './context/UserContext';
import MatchMe from './pages/matchme/MatchMe';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<PreLogin />} />
      <Route path="/login" element={<Login />} />
      <Route path="/callback" element={<AuthCallback />} />

      <Route element={<RequireAuth />}>
        <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/experience" element={<RateJobExp />} />
        <Route path="/update-resume" element={<UpdateResume />} />
        <Route path="/edit-resume" element={<EditResume />} />
        <Route path="/edit-resume_old" element={<EditResume_old />} />
        <Route path="/track-job" element={<TrackJob />} />
        <Route path="/match" element={<MatchMe />} />
        <Route path="/pricing-plan" element={<PricingPlan />} />
      </Route>
      <Route path="/success" element={<CheckoutSuccess />} />
      <Route path="/failed" element={<CheckoutFailed />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default function Router() {
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}
