import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import confettie from '../images/bg-confettie.png';
import Confetti from 'react-confetti';

const CheckoutSuccess = () => {
  const confetiRef = useRef(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {showConfetti && (
        <div ref={confetiRef}>
          <Confetti
            numberOfPieces={1500}
            tweenDuration={500}
            width={windowSize.width}
            height={windowSize.height}
          />
        </div>
      )}
      <div className="container d-flex align-items-center min-vh-100 w-100 text-center position-relative">
        <div className="my-auto mx-auto">
          <div className="d-flex align-items-center justify-content-center">
            <svg
              width="100"
              height="100"
              className="text-primary"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42Z"></path>
            </svg>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center mt-4">
              <h1 className="fw-bold text-success">Congratulation!</h1>
              <p className="w-lg-60 w-md-90 mb-2 mx-auto fw-semibold">
                You have successfully purchased your desired plan
              </p>
              <h4 className="mb-4">
                You Are One Step Closer To Saving So Much Time In Your Job
                Search!
              </h4>
              <Link to="/dashboard" className="btn btn-primary px-5">
                Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="purchase-success-bg">
        <img src={confettie} alt="404 Image" className="img-fluid" />
      </div>
    </>
  );
};

export default CheckoutSuccess;
