import React from 'react';
// import { Link } from 'react-router-dom';
import sadface from '../images/sadAnimation.gif';

const CheckoutFailed = () => {
  return (
    <div className="container d-flex align-items-center min-vh-100 w-100 text-center">
      <div className="my-auto mx-auto">
        <div className="d-flex align-items-center justify-content-center">
          <img src={sadface} alt="404 Image" className="img-fluid" />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-center mt-4">
            <h1 className="fw-bold">Uh-oh!</h1>
            <h4 className="w-lg-60 w-md-90 mb-4 mx-auto">
              Something went wrong during checkout or you might have cancelled
              it.
            </h4>
            <div className="border p-4 rounded failed-max-width mx-auto">
              <p className="fw-semibold">
                We&#39;re sorry to hear that your checkout didn&#39;t go
                through. Please try purchasing your plan again. Click below to
                return to our plans page.
              </p>
              <p className="fw-semibold">
                We&#39;re confident you&#39;ll find the perfect plan for you.
                Let&#39;s give it another shot!
              </p>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-4">
              {/* <Link to="/dashboard" className="btn btn-primary px-5">
                Dashboard
              </Link> */}
              <a
                className="btn btn-primary px-5"
                href="https://savetimeapply.com/#pricing_plans"
              >
                Go to Plans
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFailed;
