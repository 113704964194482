import React, { useState, useEffect } from 'react';
import AppBarDrawer from '../../components/layout/AppBarDrawer';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import help_circle from '../../images/help-circle.svg';
import github from '../../images/Github.svg';
import linkedin from '../../images/LinkedIn.png';
import behance from '../../images/Behance.png';
import dribble from '../../images/Dribbble.png';
import user from '../../images/user.svg';
import edit from '../../images/edit.svg';
import del from '../../images/delete.svg';
import link from '../../images/link.svg';
import spinner from '../../images/Spinner.svg';
import axios from 'axios';
import jar from '../../helpers/cookies';
import { URL } from '../../config/URL';
import { toast } from 'react-toastify';
import { useUser } from '../../context/UserContext';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const toastConfigErrorMsg = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  className: 'tosatErrorMsg'
};

function EditResume() {
  const { userProfile } = useUser();
  const bearerToken = jar.get('token');
  const UpdateResumeAPI = `${URL}/resume`;
  const location = useLocation();
  const navigate = useNavigate();
  const resumeData = location.state.resumeData;
  // console.log('Resume ID', resumeData._id);
  // console.log('Resume Key', resumeData.key);
  const [yearsOfExperience, setYearsOfExperience] = useState();
  const [veteransStatus, setVeteransStatus] = useState();
  const [criminalStatus, setCriminalStatus] = useState();
  const [disabilityStatus, setDisabilityStatus] = useState();
  const [willingToRelocate, setWillingToRelocate] = useState();
  const [requireVisaSponsorship, setRequireVisaSponsorship] = useState();
  const [hasDriverLicense, setHasDriverLicense] = useState();
  const [ethnicity, setEthnicity] = useState();
  const [autorizedWorkCountry, setAutorizedWorkCountry] = useState();
  const [excludeCompany, setExcludeCompany] = useState();
  const [expectedSalaryValue, setExpectedSalaryValue] = useState();
  const [expectedSalaryCurrency, setExpectedSalaryCurrency] = useState();
  const [currentSalaryValue, setCurrentSalaryValue] = useState();
  const [currentSalaryCurrency, setCurrentSalaryCurrency] = useState();
  const [preferredWayOfWorking, setPreferredWayOfWorking] = useState();
  const [haveOwnVehicle, setHaveOwnVehicle] = useState();
  const [hasSecurityClearance, setHasSecurityClearance] = useState();
  const [noticePeriod, setNoticePeriod] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState();
  const [addressStreet, setAddressStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [portfolioUrl, setPortfolioUrl] = useState();
  const [linkedinUrl, setLinkedinUrl] = useState();
  const [githubUrl, setGithubUrl] = useState();
  const [behanceUrl, setBehanceUrl] = useState();
  const [dribbleUrl, setDribbleUrl] = useState();
  const [summary, setSummary] = useState();
  const [coverletter, setCoverLetter] = useState();

  // useEffect(() => {
  //   if (resumeData) {
  //     setYearsOfExperience(resumeData.years_of_experience || undefined);
  //     setVeteransStatus(resumeData.veterans_status);
  //     setCriminalStatus(resumeData.criminal_status);
  //     setDisabilityStatus(resumeData.disability_status);
  //     setWillingToRelocate(resumeData.willing_to_relocate);
  //     setRequireVisaSponsorship(resumeData.require_visa_sponsorship);
  //     setHasDriverLicense(resumeData.has_driver_license);
  //     setEthnicity(resumeData.ethnicity);
  //     setAutorizedWorkCountry(resumeData.autorized_work_country);
  //     setExcludeCompany(resumeData.exclude_company);
  //     setExpectedSalaryValue(
  //       (resumeData.expected_salary && resumeData.expected_salary.value) ||
  //         undefined
  //     );
  //     setExpectedSalaryCurrency(
  //       (resumeData.expected_salary && resumeData.expected_salary.currency) ||
  //         ''
  //     );
  //     setCurrentSalaryValue(
  //       (resumeData.current_salary && resumeData.current_salary.value) ||
  //         undefined
  //     );
  //     setCurrentSalaryCurrency(
  //       (resumeData.current_salary && resumeData.current_salary.currency) || ''
  //     );
  //     setPreferredWayOfWorking(resumeData.preferred_way_of_working);
  //     setHaveOwnVehicle(resumeData.have_own_vehicle);
  //     setHasSecurityClearance(resumeData.has_security_clearance);
  //     setNoticePeriod(resumeData.notice_period_in_days);
  //     setFirstName(resumeData.first_name);
  //     setLastName(resumeData.last_name);
  //     setGender(resumeData.gender);
  //     setEmail(resumeData.email);
  //     setPhone(resumeData.phone);
  //     setCountryCode(resumeData.country_code);
  //     setAddressStreet((resumeData.address && resumeData.address.street) || '');
  //     setCity((resumeData.address && resumeData.address.city) || '');
  //     setState((resumeData.address && resumeData.address.state) || '');
  //     setPostalCode(
  //       (resumeData.address && resumeData.address.postal_code) || ''
  //     );
  //     setCountry((resumeData.address && resumeData.address.country) || '');
  //     setPortfolioUrl(resumeData.portfolio_url || '');
  //     setLinkedinUrl(resumeData.linkedin_url || '');
  //     setGithubUrl(resumeData.github_url || '');
  //     setBehanceUrl(resumeData.behance_url || '');
  //     setDribbleUrl(resumeData.dribbble_url || '');
  //     setSummary(resumeData.summary || '');
  //     setJobExperiences(resumeData.work);
  //     setEducations(resumeData.education);
  //     setAchievements(resumeData.achievements);
  //     setCertifications(resumeData.certifications);
  //     setSkills(resumeData.skills);
  //     setLanguages(resumeData.languages);
  //     setCoverLetter(resumeData.cover_letter || '');
  //   }
  // }, [resumeData]);

  useEffect(() => {
    if (resumeData) {
      const {
        years_of_experience,
        veterans_status,
        criminal_status,
        disability_status,
        willing_to_relocate,
        require_visa_sponsorship,
        has_driver_license,
        ethnicity,
        autorized_work_country,
        exclude_company,
        expected_salary,
        current_salary,
        preferred_way_of_working,
        have_own_vehicle,
        has_security_clearance,
        notice_period_in_days,
        first_name,
        last_name,
        gender,
        email,
        phone,
        country_code,
        address,
        portfolio_url,
        linkedin_url,
        github_url,
        behance_url,
        dribbble_url,
        summary,
        work,
        education,
        achievements,
        certifications,
        skills,
        languages,
        cover_letter
      } = resumeData;

      // Basic Information
      setYearsOfExperience(years_of_experience || undefined);
      setVeteransStatus(veterans_status);
      setCriminalStatus(criminal_status);
      setDisabilityStatus(disability_status);
      setWillingToRelocate(willing_to_relocate);
      setRequireVisaSponsorship(require_visa_sponsorship);
      setHasDriverLicense(has_driver_license);
      setEthnicity(ethnicity);
      setAutorizedWorkCountry(autorized_work_country);
      setExcludeCompany(exclude_company);

      // Salary Information
      setExpectedSalaryValue(expected_salary?.value || undefined);
      setExpectedSalaryCurrency(expected_salary?.currency || '');
      setCurrentSalaryValue(current_salary?.value || undefined);
      setCurrentSalaryCurrency(current_salary?.currency || '');

      // Work Preferences
      setPreferredWayOfWorking(preferred_way_of_working);
      setHaveOwnVehicle(have_own_vehicle);
      setHasSecurityClearance(has_security_clearance);
      setNoticePeriod(notice_period_in_days);

      // Personal Information
      setFirstName(first_name);
      setLastName(last_name);
      setGender(gender);
      setEmail(email);
      setPhone(phone);
      setCountryCode(country_code);

      // Address Information
      setAddressStreet(address?.street || '');
      setCity(address?.city || '');
      setState(address?.state || '');
      setPostalCode(address?.postal_code || '');
      setCountry(address?.country || '');

      // Online Presence
      setPortfolioUrl(portfolio_url);
      setLinkedinUrl(linkedin_url);
      setGithubUrl(github_url);
      setBehanceUrl(behance_url);
      setDribbleUrl(dribbble_url);

      // Summary and Additional Information
      setSummary(summary);
      setJobExperiences(work);
      setEducations(education);
      setAchievements(achievements);
      setCertifications(certifications);
      setSkills(skills);
      setLanguages(languages);

      // Cover Letter
      setCoverLetter(cover_letter || '');
    }
  }, [resumeData]);

  // console.log('jobExperiences:', jobExperiences);

  // Skills
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState();
  const [newSkillExperience, setNewSkillExperience] = useState();
  const [skillEditingIndex, setSkillEditingIndex] = useState(null);
  const [skillBtnText, setSkillBtnText] = useState('Add New');

  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
  };

  const handleSkillExpChange = (event) => {
    setNewSkillExperience(event.target.value);
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== undefined) {
      const experience =
        newSkillExperience.trim() === ''
          ? undefined
          : parseInt(newSkillExperience.trim(), 10);
      const skill = {
        name: newSkill.trim(),
        years_of_experience: experience
      };
      if (skillEditingIndex !== null) {
        const updatedSkills = [...skills];
        updatedSkills[skillEditingIndex] = skill;
        setSkills(updatedSkills);
        setSkillEditingIndex(null);
        setSkillBtnText('Add New');
      } else {
        setSkills([...skills, skill]);
      }
      setNewSkill('');
      setNewSkillExperience('');
    }
  };

  const handleEditSkill = (index) => {
    const skillToEdit = skills[index];
    setNewSkill(skillToEdit.name);
    setNewSkillExperience(skillToEdit.experience);
    setSkillEditingIndex(index);
    setSkillBtnText('Update');
  };

  const handleDeleteSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };

  // Languages
  const [languages, setLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState();
  const [newLanguageLevel, setNewLanguageLevel] = useState();
  const [languageEditingIndex, setLanguageEditingIndex] = useState(null);
  const [LanguageBtnText, setLanguageBtnText] = useState('Add New');

  const handleLanguageChange = (event) => {
    setNewLanguage(event.target.value);
  };

  const handlelanguageLevelChange = (event) => {
    setNewLanguageLevel(event.target.value);
  };

  const handleAddLanguage = () => {
    if (newLanguage.trim() !== '') {
      const language = {
        name: newLanguage.trim(),
        level: newLanguageLevel || undefined
      };
      if (languageEditingIndex !== null) {
        const updatedLanguages = [...languages];
        updatedLanguages[languageEditingIndex] = language;
        setLanguages(updatedLanguages);
        setLanguageEditingIndex(null);
        setLanguageBtnText('Add New');
      } else {
        setLanguages([...languages, language]);
      }
      setNewLanguage('');
      setNewLanguageLevel('');
    }
  };

  const handleEditLanguage = (index) => {
    const languageToEdit = languages[index];
    setNewLanguage(languageToEdit.name);
    setNewLanguageLevel(languageToEdit.level);
    setLanguageEditingIndex(index);
    setLanguageBtnText('Update');
  };

  const handleDeleteLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };

  // Experience
  const [jobExperiences, setJobExperiences] = useState([]);
  const [showExpModal, setShowExpModal] = useState(false);
  const [expFormData, setExpFormData] = useState({
    company_name: undefined,
    job_title: undefined,
    location: undefined,
    experience_type: undefined,
    start_date: undefined,
    end_date: undefined,
    description: undefined,
    currently_working: false
  });
  const [editingIndexExp, setEditingIndexExp] = useState(null);

  const handleExpInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setExpFormData({
      ...expFormData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmitExp = (e) => {
    e.preventDefault();
    if (editingIndexExp !== null) {
      const updatedJobExperiences = [...jobExperiences];
      updatedJobExperiences[editingIndexExp] = expFormData;
      setJobExperiences(updatedJobExperiences);
      setEditingIndexExp(null);
    } else {
      setJobExperiences([...jobExperiences, expFormData]);
    }

    setExpFormData({
      company_name: undefined,
      job_title: undefined,
      location: undefined,
      experience_type: undefined,
      start_date: undefined,
      end_date: undefined,
      description: undefined,
      currently_working: false
    });
  };

  const addNewExp = () => {
    setExpFormData({
      company_name: '',
      job_title: '',
      location: '',
      experience_type: '',
      start_date: '',
      end_date: '',
      description: '',
      currently_working: false
    });
    setEditingIndexExp(null);
  };

  const handleEditExp = (index) => {
    setShowExpModal(true);
    setExpFormData(jobExperiences[index]);
    setEditingIndexExp(index);
  };

  const handleDeleteExp = (index) => {
    const updatedJobExperiences = [...jobExperiences];
    updatedJobExperiences.splice(index, 1);
    setJobExperiences(updatedJobExperiences);
  };

  // Education
  const [educations, setEducations] = useState([]);
  const [showEduModal, setShowEduModal] = useState(false);
  const [eduFormData, setEduFormData] = useState({
    institution_name: undefined,
    institution_city: undefined,
    institution_country: undefined,
    institution_type: undefined,
    degree_level: undefined,
    degree_title: undefined,
    grade: undefined,
    start_date: undefined,
    end_date: undefined,
    currently_studying: false,
    description: undefined
  });
  const [editingIndexEdu, setEditingIndexEdu] = useState(null);

  const handleEduInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEduFormData({
      ...eduFormData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmitEdu = (e) => {
    e.preventDefault();
    if (editingIndexEdu !== null) {
      const updatedJobEducations = [...educations];
      updatedJobEducations[editingIndexEdu] = eduFormData;
      setEducations(updatedJobEducations);
      setEditingIndexEdu(null);
    } else {
      setEducations([...educations, eduFormData]);
    }

    setEduFormData({
      institution_name: undefined,
      institution_city: undefined,
      institution_country: undefined,
      institution_type: undefined,
      degree_level: undefined,
      degree_title: undefined,
      grade: undefined,
      start_date: undefined,
      end_date: undefined,
      currently_studying: false,
      description: undefined
    });
  };

  const addNewEdu = () => {
    setEduFormData({
      institution_name: '',
      institution_city: '',
      institution_country: '',
      institution_type: '',
      degree_level: '',
      degree_title: '',
      grade: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
      description: ''
    });
    setEditingIndexEdu(null);
  };

  const handleEditEdu = (index) => {
    setShowEduModal(true);
    setEduFormData(educations[index]);
    setEditingIndexEdu(index);
  };

  const handleDeleteEdu = (index) => {
    const updatedEducations = [...educations];
    updatedEducations.splice(index, 1);
    setEducations(updatedEducations);
  };

  // Achievements
  const [achievements, setAchievements] = useState([]);
  const [showAchModal, setShowAchModal] = useState(false);
  const [achFormData, setAchFormData] = useState({
    award_name: undefined,
    issued_by: undefined,
    achievement_date: undefined,
    description: undefined
  });
  const [editingIndexAch, setEditingIndexAch] = useState(null);

  const handleAchInputChange = (e) => {
    const { name, value } = e.target;
    setAchFormData({
      ...achFormData,
      [name]: value
    });
  };

  const handleSubmitAch = (e) => {
    e.preventDefault();
    if (editingIndexAch !== null) {
      const updatedAchievements = [...achievements];
      updatedAchievements[editingIndexAch] = achFormData;
      setAchievements(updatedAchievements);
      setEditingIndexAch(null);
    } else {
      setAchievements([...achievements, achFormData]);
    }

    setAchFormData({
      award_name: undefined,
      issued_by: undefined,
      achievement_date: undefined,
      description: undefined
    });
  };

  const addNewAch = () => {
    setAchFormData({
      award_name: '',
      issued_by: '',
      achievement_date: '',
      description: ''
    });
    setEditingIndexAch(null);
  };

  const handleEditAch = (index) => {
    setShowAchModal(true);
    setAchFormData(achievements[index]);
    setEditingIndexAch(index);
  };

  const handleDeleteAch = (index) => {
    const updatedAchievements = [...achievements];
    updatedAchievements.splice(index, 1);
    setAchievements(updatedAchievements);
  };

  //  Certification
  const [certifications, setCertifications] = useState([]);
  const [showCertModal, setShowCertModal] = useState(false);
  const [certFormData, setCertFormData] = useState({
    name: undefined,
    issued_by: undefined,
    start_date: undefined,
    end_date: undefined,
    link: undefined,
    certificate_id: undefined,
    description: undefined
  });
  const [editingIndexCert, setEditingIndexCert] = useState(null);

  const handleCertInputChange = (e) => {
    const { name, value } = e.target;
    setCertFormData({
      ...certFormData,
      [name]: value
    });
  };

  const handleSubmitCert = (e) => {
    e.preventDefault();
    if (editingIndexCert !== null) {
      const updatedCertifications = [...certifications];
      updatedCertifications[editingIndexCert] = certFormData;
      setCertifications(updatedCertifications);
      setEditingIndexCert(null);
    } else {
      setCertifications([...certifications, certFormData]);
    }

    setCertFormData({
      name: undefined,
      issued_by: undefined,
      start_date: undefined,
      end_date: undefined,
      link: undefined,
      certificate_id: undefined,
      description: undefined
    });
  };

  const addNewCert = () => {
    setCertFormData({
      name: '',
      issued_by: '',
      start_date: '',
      end_date: '',
      link: '',
      certificate_id: '',
      description: ''
    });
    setEditingIndexCert(null);
  };

  const handleEditCert = (index) => {
    setShowCertModal(true);
    setCertFormData(certifications[index]);
    setEditingIndexCert(index);
  };

  const handleDeleteCert = (index) => {
    const updatedCertifications = [...certifications];
    updatedCertifications.splice(index, 1);
    setCertifications(updatedCertifications);
  };

  //Cover Letter

  const [jobTitle, setJobTitle] = useState('');
  const [generatedCoverLetter, setGeneratedCoverLetter] = useState('');
  const [isGenerated, setIsGenerated] = useState(false);
  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);

  const coverLetterThreshold = userProfile?.activation?.cover_letter_threshold;

  const handleGenerate = async () => {
    setIsLoadingGenerate(true);
    try {
      const response = await axios.post(
        `${URL}/generate/cover-letter`,
        {
          resumeId: resumeData._id,
          job_title: jobTitle
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      );

      setGeneratedCoverLetter(response.data.data);
      setIsGenerated(true);
      console.log(response.data.msg);
    } catch (error) {
      console.error('Error generating cover letter:', error);
      toast.error('Error generating cover letter', toastConfig);
    } finally {
      setIsLoadingGenerate(false);
    }
  };

  const handleSave = () => {
    setCoverLetter(generatedCoverLetter);
    console.log('Saved:', generatedCoverLetter);
  };
  // Update Resume
  const updateResume = () => {
    const sanitizedSkills = skills.map((skill) => ({
      name: skill.name,
      years_of_experience:
        skill.years_of_experience === null
          ? undefined
          : skill.years_of_experience
    }));
    const updatedResumeData = {
      years_of_experience: isNaN(yearsOfExperience)
        ? undefined
        : yearsOfExperience,
      veterans_status: veteransStatus === '' ? undefined : veteransStatus,
      criminal_status: criminalStatus === '' ? undefined : criminalStatus,
      disability_status: disabilityStatus === '' ? undefined : disabilityStatus,
      willing_to_relocate:
        willingToRelocate === '' ? undefined : willingToRelocate,
      require_visa_sponsorship:
        requireVisaSponsorship === '' ? undefined : requireVisaSponsorship,
      has_driver_license:
        hasDriverLicense === '' ? undefined : hasDriverLicense,
      ethnicity: ethnicity === '' ? undefined : ethnicity,
      autorized_work_country:
        autorizedWorkCountry === '' ? undefined : autorizedWorkCountry,
      exclude_company: excludeCompany === '' ? undefined : excludeCompany,
      preferred_way_of_working:
        preferredWayOfWorking === '' ? undefined : preferredWayOfWorking,
      have_own_vehicle: haveOwnVehicle === '' ? undefined : haveOwnVehicle,
      has_security_clearance:
        hasSecurityClearance === '' ? undefined : hasSecurityClearance,
      notice_period_in_days: isNaN(noticePeriod) ? noticePeriod : undefined,
      expected_salary: {
        value:
          isNaN(expectedSalaryValue) === '' ? undefined : expectedSalaryValue,
        currency:
          expectedSalaryCurrency === '' ? undefined : expectedSalaryCurrency
      },
      current_salary: {
        value:
          isNaN(currentSalaryValue) === '' ? undefined : currentSalaryValue,
        currency:
          currentSalaryCurrency === '' ? undefined : currentSalaryCurrency
      },
      first_name: firstName === '' ? undefined : firstName,
      last_name: lastName === '' ? undefined : lastName,
      gender: gender === '' ? undefined : gender,
      email: email === '' ? undefined : email,
      phone: phone === '' ? undefined : phone,
      country_code: countryCode === '' ? undefined : countryCode,
      portfolio_url: portfolioUrl === '' ? undefined : portfolioUrl,
      linkedin_url: linkedinUrl === '' ? undefined : linkedinUrl,
      github_url: githubUrl === '' ? undefined : githubUrl,
      behance_url: behanceUrl === '' ? undefined : behanceUrl,
      dribbble_url: dribbleUrl === '' ? undefined : dribbleUrl,
      summary: summary === '' ? undefined : summary,
      address: {
        street: addressStreet === '' ? undefined : addressStreet,
        city: city === '' ? undefined : city,
        state: state === '' ? undefined : state,
        postal_code: postalCode === '' ? undefined : postalCode,
        country: country === '' ? undefined : country
      },
      work: jobExperiences.length === 0 ? undefined : jobExperiences,
      education: educations.length === 0 ? undefined : educations,
      skills: sanitizedSkills.length === 0 ? undefined : sanitizedSkills,
      languages: languages.length === 0 ? undefined : languages,
      achievements: achievements.length === 0 ? undefined : achievements,
      certifications: certifications.length === 0 ? undefined : certifications,
      cover_letter: coverletter === '' ? undefined : coverletter
    };

    axios
      .post(`${UpdateResumeAPI}/${resumeData._id}`, updatedResumeData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      })
      .then((response) => {
        console.log('Resume updated successfully:', response.data);
        toast.success(response.data.msg, toastConfig);
        navigate('/update-resume');
      })
      .catch((error) => {
        console.error('Error updating resume data:', error);

        if (error.response && error.response.status === 422) {
          const validationErrors = error.response.data.data;
          const errorMessages = validationErrors.map((error) => {
            const field = error.path.split('.')[1];
            const errorMessage = field;
            return `${errorMessage}: ${error.msg}\n`;
          });
          const errorMessage = errorMessages.join('\n');
          toast.error(
            `Validation Error: \n${errorMessage}`,
            toastConfigErrorMsg
          );
          console.log('Toast Message', errorMessage);
        } else {
          toast.error('Something went wrong', toastConfig);
        }
      });
  };

  return (
    <AppBarDrawer>
      <div className="container-fluid p-4 mb-5">
        <button
          className="btn btn-primary update-resume-btn px-5"
          type="button"
          onClick={updateResume}
        >
          Update Resume
        </button>
        <div className="aboutme-section py-4 px-3 mb-2">
          <h3>Other Information</h3>
          <div className="row pt-2">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="experience" className="form-label">
                  Total Experience (in years)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="experience"
                  placeholder="5 Years"
                  name="years_of_experience"
                  value={yearsOfExperience}
                  onChange={(e) =>
                    setYearsOfExperience(parseInt(e.target.value))
                  }
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Veteran Status</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="veterans_status"
                  value={veteransStatus}
                  onChange={(e) => setVeteransStatus(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="none">I do not wish to answer</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Disability</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="disability_status"
                  value={disabilityStatus}
                  onChange={(e) => setDisabilityStatus(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Criminal Status</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="criminal_status"
                  value={criminalStatus}
                  onChange={(e) => setCriminalStatus(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Willing to relocate?</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="willing_to_relocate"
                  value={willingToRelocate}
                  onChange={(e) => setWillingToRelocate(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Race/Ethnicity</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="ethnicity"
                  value={ethnicity}
                  onChange={(e) => setEthnicity(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="hispanic_or_latino">Hispanic or Latino</option>
                  <option value="american_indian_or_alaskan_native">
                    American Indian or Alaska Native
                  </option>
                  <option value="asian">Asian</option>
                  <option value="black_or_african_american">
                    Black or African American
                  </option>
                  <option value="native_hawaiian_or_other_pacific_islander">
                    Native Hawaiian or Other Pacific Islander
                  </option>
                  <option value="white">White</option>
                  <option value="two_or_more_races">Two or more races</option>
                  <option value="decline_to_self_identify">
                    Decline to self identify
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="experience" className="form-label">
                  Expected Salary
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="experience"
                  placeholder="Salary"
                  name="expected_salary.value"
                  value={expectedSalaryValue}
                  onChange={(e) => {
                    setExpectedSalaryValue(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Currency</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="expected_salary.currency"
                  value={expectedSalaryCurrency}
                  onChange={(e) => {
                    setExpectedSalaryCurrency(e.target.value);
                  }}
                >
                  <option value="">Select Currency</option>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                  <option value="CAD">CAD</option>
                  <option value="NZD">NZD</option>
                  <option value="ZAR">ZAR</option>
                  <option value="AUD">AUD</option>
                  <option value="EUR">EUR</option>
                  <option value="BRL">BRL</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="experience" className="form-label">
                  Current Salary
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="experience"
                  placeholder="Salary"
                  name="current_salary.value"
                  value={currentSalaryValue}
                  onChange={(e) => {
                    setCurrentSalaryValue(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Currency</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="current_salary.currency"
                  value={currentSalaryCurrency}
                  onChange={(e) => {
                    setCurrentSalaryCurrency(e.target.value);
                  }}
                >
                  <option value="">Select Currency</option>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                  <option value="CAD">CAD</option>
                  <option value="NZD">NZD</option>
                  <option value="ZAR">ZAR</option>
                  <option value="AUD">AUD</option>
                  <option value="EUR">EUR</option>
                  <option value="BRL">BRL</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Driving License</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="has_driver_license"
                  value={hasDriverLicense}
                  onChange={(e) => setHasDriverLicense(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Do you have your own vehicle?</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="have_own_vehicle"
                  value={haveOwnVehicle}
                  onChange={(e) => setHaveOwnVehicle(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Higher Education Level</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                >
                  <option value="">Select Degree</option>
                  <option value="No formal education">
                    No formal education
                  </option>
                  <option value="Primary education">Primary education</option>
                  <option value="Secondary education">
                    Secondary education or high school
                  </option>
                  <option value="GED">GED</option>
                  <option value="Vocational qualification">
                    Vocational qualification
                  </option>
                  <option value="Bachelor's degree">
                    Bachelor&apos;s degree
                  </option>
                  <option value="Master's degree">Master&apos;s degree</option>
                  <option value="Doctorate or higher">
                    Doctorate or higher
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">Prefered Way of working</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="preferred_way_of_working"
                  value={preferredWayOfWorking}
                  onChange={(e) => setPreferredWayOfWorking(e.target.value)}
                >
                  <option value="">Select Option</option>
                  <option value="on-site">On-site</option>
                  <option value="remote">Remote</option>
                  <option value="hybrid">Hybrid</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="experience" className="form-label">
                  Notice Periods (in Days)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="experience"
                  placeholder="5 Days"
                  name="notice_period_in_days"
                  value={noticePeriod}
                  onChange={(e) => {
                    setNoticePeriod(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="authorisedCountries" className="form-label">
                  Countries you are authorized to work?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="authorisedCountries"
                  name="autorized_work_country"
                  placeholder="United States, United Kingdom, ..."
                  value={autorizedWorkCountry}
                  onChange={(e) => {
                    setAutorizedWorkCountry(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="experience" className="form-label">
                  Companies to exclude from applying (comma separated)
                  (Optional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="experience"
                  placeholder="Amazon, Google etc"
                  value={excludeCompany}
                  onChange={(e) => {
                    setExcludeCompany(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">
                  Will you now, or in the future, require sponsorship for
                  employment visa status (e.g. H-1B visa status)?
                </p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="require_visa_sponsorship"
                  value={requireVisaSponsorship}
                  onChange={(e) => {
                    setRequireVisaSponsorship(e.target.value);
                  }}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <p className="mb-2">
                  Do you have an active security clearance? (Optional)
                </p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="has_security_clearance"
                  value={hasSecurityClearance}
                  onChange={(e) => {
                    setHasSecurityClearance(e.target.value);
                  }}
                >
                  <option value="">Select Option</option>
                  <option value="true">Yes</option>
                  <option value="false">No / Not Applicable</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutme-section py-4 px-3 mb-2">
          <h3>Tell us a little bit about you..?</h3>
          <div className="row mt-4 justify-content-between">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="mb-3 aboutme-input">
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  placeholder="First Name"
                  name="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="mb-3 aboutme-input">
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  placeholder="Last Name"
                  name="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="mb-3 aboutme-input">
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="none">Prefer not to say</option>
                </select>
              </div>
              <div className="mb-3 aboutme-input">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <div className="col-4 pe-1">
                  <input
                    type="tel"
                    className="form-control"
                    id="country_code"
                    placeholder="Country Code"
                    name="country_code"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                  />
                </div>
                <div className="col-8">
                  <input
                    type="tel"
                    className="form-control"
                    id="phone_number"
                    placeholder="Phone Number"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3 aboutme-input">
                <input
                  type="address"
                  className="form-control"
                  id="address"
                  placeholder="Address"
                  name="address.street"
                  value={addressStreet}
                  onChange={(e) => setAddressStreet(e.target.value)}
                />
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <div className="col-6 pe-1">
                  <input
                    type="text"
                    className="form-control"
                    id="city_name"
                    placeholder="City Name"
                    name="adress.city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder="State"
                    name="address.state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <div className="col-6 pe-1">
                  <input
                    type="text"
                    className="form-control"
                    id="postal_code"
                    placeholder="Zip/Postal Code"
                    name="address.postal_code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    placeholder="Country"
                    name="address.country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-3 d-flex aboutme-input">
                <img className="me-3" src={github} alt="GitHub" />
                <input
                  type="url"
                  className="form-control"
                  id="github"
                  placeholder="Enter GitHub Link"
                  name="github_url"
                  value={githubUrl}
                  onChange={(e) => setGithubUrl(e.target.value)}
                />
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <img className="me-3" src={linkedin} alt="LinkedIn" />
                <input
                  type="url"
                  className="form-control"
                  id="linkedin"
                  placeholder="Enter Linkedin Profile"
                  name="linkedin_url"
                  value={linkedinUrl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                />
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <img className="me-3" src={behance} alt="Behance" />
                <input
                  type="url"
                  className="form-control"
                  id="behance"
                  placeholder="Enter Behance Profile"
                  name="behance_url"
                  value={behanceUrl}
                  onChange={(e) => setBehanceUrl(e.target.value)}
                />
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <img className="me-3" src={dribble} alt="Dribble" />
                <input
                  type="url"
                  className="form-control"
                  id="dribble"
                  placeholder="Enter Dribble Profile"
                  name="dribbble_url"
                  value={dribbleUrl}
                  onChange={(e) => setDribbleUrl(e.target.value)}
                />
              </div>
              <div className="mb-3 d-flex aboutme-input">
                <img className="me-3" src={user} alt="User Portfolio" />
                <input
                  type="url"
                  className="form-control"
                  id="portfolio"
                  placeholder="Personal Website/Portfolio Link"
                  name="portfolio_url"
                  value={portfolioUrl}
                  onChange={(e) => setPortfolioUrl(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3 aboutme-input">
                <label htmlFor="resume_summary" className="form-label">
                  Resume Summary
                </label>
                <textarea
                  className="form-control"
                  id="resume_summary"
                  name="summary"
                  rows="5"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        {/* Work Experience */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <div className="d-flex justify-content-between">
            <h3>Work Experience</h3>
            <button
              type="button"
              className="btn btn-primary px-5"
              data-bs-toggle="modal"
              data-bs-target="#jobExperienceModal"
              onClick={addNewExp}
            >
              Add New
            </button>
          </div>

          <div className="mt-4">
            {jobExperiences.map((experience, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between mb-0">
                  <h5>{experience.job_title}</h5>
                  <div className="d-flex gap-1">
                    <button
                      type="button"
                      className="btn bg-transparent pt-0 border border-0"
                      onClick={() => handleEditExp(index)}
                      data-bs-toggle="modal"
                      data-bs-target="#jobExperienceModal"
                    >
                      <img src={edit} alt="Edit" />
                    </button>
                    <button
                      type="button"
                      className="btn bg-transparent pt-0 "
                      onClick={() => handleDeleteExp(index)}
                    >
                      <img src={del} alt="Delete" />
                    </button>
                  </div>
                </div>
                <h5 className="mb-2">
                  {experience.company_name} | {experience.experience_type}
                </h5>
                <p className="aboutme-date-location mb-2">
                  {experience.start_date} -{' '}
                  {experience.currently_working
                    ? 'Present'
                    : experience.end_date}
                  {' | '}
                  {experience.location}
                </p>
                <p> {experience.description} </p>
              </div>
            ))}
          </div>

          {/* <!-- Experience Modal --> */}
          <div
            className="modal fade ms-5 mt-5"
            id="jobExperienceModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden={!showExpModal}
          >
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
              <div className="modal-content modal-aboutme">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    {editingIndexExp !== null
                      ? 'Edit Work Experience'
                      : 'Work Experience'}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmitExp}>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="companyName" className="form-label">
                            Company Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="company_name"
                            value={expFormData.company_name}
                            onChange={handleExpInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="jobTitle" className="form-label">
                            Job Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="jobTitle"
                            name="job_title"
                            value={expFormData.job_title}
                            onChange={handleExpInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="jobLocation" className="form-label">
                            Location
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="jobLocation"
                            name="location"
                            value={expFormData.location}
                            onChange={handleExpInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <p className="mb-2">Experience Type</p>
                          <select
                            className="py-1 px-2 w-100"
                            aria-label="Example select with button addon"
                            id="jobType"
                            name="experience_type"
                            value={expFormData.experience_type}
                            onChange={handleExpInputChange}
                          >
                            <option value="">Select Option</option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Internship">Internship</option>
                            <option value="Contract">Contract</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="jobStartDate" className="form-label">
                            Starting Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="jobStartDate"
                            name="start_date"
                            value={expFormData.start_date}
                            onChange={handleExpInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="jobEndDate" className="form-label">
                            {expFormData.currently_working
                              ? 'Present'
                              : 'Ending Date'}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="jobEndDate"
                            name="end_date"
                            value={
                              expFormData.currently_working
                                ? undefined
                                : expFormData.end_date
                            }
                            onChange={handleExpInputChange}
                            disabled={expFormData.currently_working}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-group mb-2">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="currentlyWorkHere"
                              name="currently_working"
                              checked={expFormData.currently_working}
                              onChange={handleExpInputChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="currentlyWorkHere"
                            >
                              Currently Work Here?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="jobDescription"
                            className="form-label"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="jobDescription"
                            name="description"
                            rows="4"
                            value={expFormData.description}
                            onChange={handleExpInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary px-5"
                        data-bs-dismiss="modal"
                      >
                        {editingIndexExp !== null ? 'Save Changes' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Education */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <div className="d-flex justify-content-between">
            <h3>Education</h3>
            <button
              type="button"
              className="btn btn-primary px-5"
              data-bs-toggle="modal"
              data-bs-target="#educationModal"
              onClick={addNewEdu}
            >
              Add New
            </button>
          </div>

          <div className="mt-4">
            {educations.map((education, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between mb-0">
                  <h5>
                    {education.degree_title} | {education.institution_name} |{' '}
                    {education.institution_city} |{' '}
                    {education.institution_country}
                  </h5>
                  <div className="d-flex gap-1">
                    <button
                      type="button"
                      className="btn bg-transparent pt-0 border-0"
                      onClick={() => handleEditEdu(index)}
                      data-bs-toggle="modal"
                      data-bs-target="#educationModal"
                    >
                      <img src={edit} alt="Edit" />
                    </button>
                    <button
                      type="button"
                      className="btn bg-transparent pt-0"
                      onClick={() => handleDeleteEdu(index)}
                    >
                      <img src={del} alt="Delete" />
                    </button>
                  </div>
                </div>
                <h5 className="aboutme-date-location mb-2">
                  {education.degree_level} | {education.institution_type}
                </h5>
                <p className="aboutme-date-location mb-2">
                  {education.start_date} -{' '}
                  {education.currently_studying
                    ? 'Present'
                    : education.end_date}
                </p>
                <p className="aboutme-date-location mb-2">
                  GPA/Grade - {education.grade}
                </p>
                <p> {education.description} </p>
              </div>
            ))}
          </div>

          {/* <!-- Education Modal --> */}
          <div
            className="modal fade ms-5 mt-5"
            id="educationModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden={!showEduModal}
          >
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
              <div className="modal-content modal-aboutme">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    {editingIndexEdu !== null ? 'Edit Education' : 'Education'}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmitEdu}>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="education_level"
                            className="form-label"
                          >
                            Degree Level
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="education_level"
                            name="degree_level"
                            placeholder="Ex: Bachelor's of Software Engineering"
                            value={eduFormData.degree_level}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="education_degree"
                            className="form-label"
                          >
                            Degree Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="education_degree"
                            name="degree_title"
                            placeholder="Ex: Bachelor's of Software Engineering"
                            value={eduFormData.degree_title}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="institutionName"
                            className="form-label"
                          >
                            Institution Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="institutionName"
                            name="institution_name"
                            placeholder="Ex: Boston University"
                            value={eduFormData.institution_name}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="institutionType"
                            className="form-label"
                          >
                            Institution Type
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="institutionType"
                            name="institution_type"
                            placeholder="Ex: University"
                            value={eduFormData.institution_type}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="institutionCity"
                            className="form-label"
                          >
                            Institution City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="institutionCity"
                            name="institution_city"
                            placeholder="Ex: London"
                            value={eduFormData.institution_city}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="institutionCity"
                            className="form-label"
                          >
                            Institution Country
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="institutionCity"
                            name="institution_country"
                            placeholder="Ex: United Kingdom"
                            value={eduFormData.institution_country}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="field_of_study"
                            className="form-label"
                          >
                            Field of Study
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="field_of_study"
                            name="field_of_study"
                            placeholder="Ex: Business"
                            value={eduFormData.field_of_study}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="gpaGrade" className="form-label">
                            GPA/Grade
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="gpaGrade"
                            name="grade"
                            placeholder="GPA / Grade"
                            value={eduFormData.grade}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="education_start_date"
                            className="form-label"
                          >
                            Starting Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="education_start_date"
                            name="start_date"
                            value={eduFormData.start_date}
                            onChange={handleEduInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="jobEndDate" className="form-label">
                            {eduFormData.currently_studying
                              ? 'Present'
                              : 'Ending Date'}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="jobEndDate"
                            name="end_date"
                            value={
                              eduFormData.currently_studying
                                ? undefined
                                : eduFormData.end_date
                            }
                            onChange={handleEduInputChange}
                            disabled={eduFormData.currently_studying}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-group mb-2">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="currentlyStudying"
                              name="currently_studying"
                              checked={eduFormData.currently_studying}
                              onChange={handleEduInputChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="currentlyStudying"
                            >
                              Currently Studying Here?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="jobDescription"
                            className="form-label"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="jobDescription"
                            name="description"
                            rows="4"
                            value={eduFormData.description}
                            onChange={handleEduInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary px-5"
                        data-bs-dismiss="modal"
                      >
                        {editingIndexEdu !== null ? 'Save Changes' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Skills */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <h3>Skills</h3>
          <div className="row mt-3">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12 pe-2">
                  <div className="mb-3 aboutme-input">
                    <input
                      type="text"
                      className="form-control"
                      id="skillName"
                      name="skillName"
                      placeholder="Skill Name"
                      value={newSkill}
                      onChange={handleSkillChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 ps-2">
                  <div className="mb-3 aboutme-input">
                    <input
                      type="number"
                      className="form-control"
                      id="skillExperience"
                      name="skillExperience"
                      placeholder="Years of Experience"
                      value={newSkillExperience}
                      onChange={handleSkillExpChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 text-end">
              <button
                className="btn btn-primary px-5 my-auto"
                type="button"
                onClick={handleAddSkill}
              >
                {skillBtnText}
              </button>
            </div>
          </div>
          <div className="row mt-3 px-1">
            {skills.map((skill, index) => (
              <div
                className="col-3 text-center px-2"
                id={`skill-${index}`}
                key={index}
              >
                <p className="new-skills">
                  {skill.name}{' '}
                  <span className="small mb-2 skillExp">
                    (Exp:{' '}
                    {skill.years_of_experience === ''
                      ? undefined
                      : skill.years_of_experience}{' '}
                    Years)
                  </span>
                  <button
                    className="skill-del-btn bg-transparent border-0"
                    type="button"
                    onClick={() => handleEditSkill(index)}
                  >
                    <svg
                      width={15}
                      height={15}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m14.059 9.02.92.92L5.919 19h-.92v-.92l9.06-9.06Zm3.6-6.02c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29Zm-3.6 3.19L2.999 17.25V21h3.75l11.06-11.06-3.75-3.75Z"></path>
                    </svg>
                  </button>
                  <button
                    className="skill-del-btn bg-transparent border-0"
                    type="button"
                    onClick={() => handleDeleteSkill(index)}
                  >
                    <svg
                      width={15}
                      height={15}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                      <path d="m15 9-6 6" />
                      <path d="m9 9 6 6" />
                    </svg>
                  </button>
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* Languages */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <h3>Languages</h3>
          <div className="row mt-3">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12 pe-2">
                  <div className="mb-3 aboutme-input">
                    <input
                      type="text"
                      className="form-control"
                      id="languageName"
                      name="languageName"
                      placeholder="Language Name"
                      value={newLanguage}
                      onChange={handleLanguageChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 ps-2">
                  <div className="mb-3 aboutme-input">
                    <select
                      className="py-1 px-2 w-100"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                      name="newLanguageLevel"
                      value={newLanguageLevel}
                      onChange={handlelanguageLevelChange}
                    >
                      <option value="">Proficiency Level</option>
                      <option value="beginner">Beginner</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="advanced">Advanced</option>
                      <option value="Native">Native</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 text-end">
              <button
                className="btn btn-primary px-5 my-auto"
                type="button"
                onClick={handleAddLanguage}
              >
                {LanguageBtnText}
              </button>
            </div>
          </div>
          <div className="row mt-3 px-1">
            {languages.map((language, index) => (
              <div
                className="col-3 text-center px-2"
                id={`skill-${index}`}
                key={index}
              >
                <p className="new-skills">
                  {language.name} | {language.level}
                  <button
                    className="skill-del-btn bg-transparent border-0"
                    type="button"
                    onClick={() => handleEditLanguage(index)}
                  >
                    <svg
                      width={15}
                      height={15}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m14.059 9.02.92.92L5.919 19h-.92v-.92l9.06-9.06Zm3.6-6.02c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29Zm-3.6 3.19L2.999 17.25V21h3.75l11.06-11.06-3.75-3.75Z"></path>
                    </svg>
                  </button>
                  <button
                    className="skill-del-btn bg-transparent border-0"
                    type="button"
                    onClick={() => handleDeleteLanguage(index)}
                  >
                    <svg
                      width={15}
                      height={15}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                      <path d="m15 9-6 6" />
                      <path d="m9 9 6 6" />
                    </svg>
                  </button>
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* Achievement */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <div className="d-flex justify-content-between">
            <h3>Achievements</h3>
            <button
              type="button"
              className="btn btn-primary px-5"
              data-bs-toggle="modal"
              data-bs-target="#achievementModal"
              onClick={addNewAch}
            >
              Add New
            </button>
          </div>

          <div className="mt-4">
            {achievements.map((achievement, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between mb-0">
                  <h5>{achievement.award_name}</h5>
                  <div className="d-flex gap-1">
                    <button
                      type="button"
                      className="btn bg-transparent pt-0"
                      onClick={() => handleEditAch(index)}
                      data-bs-toggle="modal"
                      data-bs-target="#achievementModal"
                    >
                      <img src={edit} alt="Edit" />
                    </button>
                    <button
                      type="button"
                      className="btn bg-transparent pt-0"
                      onClick={() => handleDeleteAch(index)}
                    >
                      <img src={del} alt="Delete" />
                    </button>
                  </div>
                </div>
                <p className="aboutme-date-location mb-2">
                  {achievement.issued_by} | {achievement.achievement_date}
                </p>
                <p> {achievement.description} </p>
              </div>
            ))}
          </div>

          {/* <!-- Achievement Modal --> */}
          <div
            className="modal fade ms-5 mt-5"
            id="achievementModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden={!showAchModal}
          >
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
              <div className="modal-content modal-aboutme">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    {editingIndexAch !== null
                      ? 'Edit Achievement'
                      : 'Achievement'}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmitAch}>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="achievName" className="form-label">
                            Award Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="achievName"
                            name="award_name"
                            placeholder="Achievement"
                            value={achFormData.award_name}
                            onChange={handleAchInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="achievIssuedBy"
                            className="form-label"
                          >
                            Issued By
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="achievIssuedBy"
                            name="issued_by"
                            placeholder="Issued by"
                            value={achFormData.issued_by}
                            onChange={handleAchInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="achievDate" className="form-label">
                            Achievement Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="achievDate"
                            name="achievement_date"
                            value={achFormData.achievement_date}
                            onChange={handleAchInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="achievDescription"
                            className="form-label"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="achievDescription"
                            name="description"
                            rows="4"
                            placeholder="Start typing here..."
                            value={achFormData.description}
                            onChange={handleAchInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary px-5"
                        data-bs-dismiss="modal"
                      >
                        {editingIndexAch !== null ? 'Save Changes' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Certification */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <div className="d-flex justify-content-between">
            <h3>Certification</h3>
            <button
              type="button"
              className="btn btn-primary px-5"
              data-bs-toggle="modal"
              data-bs-target="#certificationModal"
              onClick={addNewCert}
            >
              Add New
            </button>
          </div>

          <div className="mt-4">
            {certifications.map((certification, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between mb-0">
                  <h5>{certification.name}</h5>
                  <div className="d-flex gap-1">
                    <a
                      className="btn bg-transparent text-decoration-none pt-0"
                      href={certification.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={link} alt="Link Icon" />
                    </a>
                    <button
                      type="button"
                      className="btn bg-transparent pt-0"
                      onClick={() => handleEditCert(index)}
                      data-bs-toggle="modal"
                      data-bs-target="#certificationModal"
                    >
                      <img src={edit} alt="Edit" />
                    </button>
                    <button
                      type="button"
                      className="btn bg-transparent pt-0"
                      onClick={() => handleDeleteCert(index)}
                    >
                      <img src={del} alt="Delete" />
                    </button>
                  </div>
                </div>
                <p className="aboutme-date-location mb-2">
                  {certification.issued_by} | {certification.start_date} -{' '}
                  {certification.end_date}
                </p>
                <p className="aboutme-date-location mb-2">
                  {certification.certificate_id}
                </p>
                <p> {certification.description} </p>
              </div>
            ))}
          </div>

          {/* <!-- Certification Modal --> */}
          <div
            className="modal fade ms-5 mt-5"
            id="certificationModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden={!showCertModal}
          >
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
              <div className="modal-content modal-aboutme">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    {editingIndexCert !== null
                      ? 'Edit Certification'
                      : 'Certification'}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmitCert}>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="certName" className="form-label">
                            Certificate Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="certName"
                            name="name"
                            placeholder="Certificate Name"
                            value={certFormData.name}
                            onChange={handleCertInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="certIssuedBy" className="form-label">
                            Issued By
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="certIssuedBy"
                            name="issued_by"
                            placeholder="Institute Name"
                            value={certFormData.issued_by}
                            onChange={handleCertInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="certStartDate" className="form-label">
                            Start Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="certStartDate"
                            name="start_date"
                            value={certFormData.start_date}
                            onChange={handleCertInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="certEndDate" className="form-label">
                            End Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="certEndDate"
                            name="end_date"
                            value={certFormData.end_date}
                            onChange={handleCertInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="certLink" className="form-label">
                            Certification Link (Optional)
                          </label>
                          <input
                            type="url"
                            className="form-control"
                            id="certLink"
                            name="link"
                            placeholder="Enter link here"
                            value={certFormData.link}
                            onChange={handleCertInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label htmlFor="certId" className="form-label">
                            Certification ID (Optional)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="certId"
                            name="certificate_id"
                            placeholder="Enter Certificate ID"
                            value={certFormData.certificate_id}
                            onChange={handleCertInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="mb-3 aboutme-input">
                          <label
                            htmlFor="certDescription"
                            className="form-label"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="certDescription"
                            name="description"
                            rows="4"
                            placeholder="Start typing here..."
                            value={certFormData.description}
                            onChange={handleCertInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary px-5"
                        data-bs-dismiss="modal"
                      >
                        {editingIndexCert !== null ? 'Save Changes' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cover Letter */}
        <div className="aboutme-section py-4 px-3 mb-2">
          <div className="cover-letter">
            <div className="d-flex justify-content-between">
              <h3 className="me-4">Cover Letter</h3>
              <button
                type="button"
                data-bs-target="#coverLetterModal"
                data-bs-toggle="modal"
                className="help-genetate bg-transparent border-0"
              >
                Generate With AI <img src={help_circle} alt="Help Circle" />
              </button>
            </div>
            {coverLetterThreshold === 0 ? (
              <div>
                <span className="text-danger">
                  Remaining Cover Letters: {coverLetterThreshold}
                </span>
                <span className="ps-3">
                  <Link
                    className="text-decoration-none fw-bold"
                    to={'/pricing-plan'}
                  >
                    Upgrade plan
                  </Link>{' '}
                  to get more cover letters
                </span>
              </div>
            ) : (
              <p className="text-success">
                Remaining Cover Letters: {coverLetterThreshold}
              </p>
            )}
            <div className="aboutme-input">
              <textarea
                className="form-control w-100 mt-3"
                name="Cover Letter"
                placeholder="Write here"
                id="coverletter"
                rows="7"
                value={coverletter}
                onChange={(e) => {
                  setCoverLetter(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          {/* Cover Letter Modal */}
          <div
            className="modal fade ms-5 mt-5"
            id="coverLetterModal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
          >
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
              <div className="modal-content modal-aboutme">
                <div className="modal-header">
                  <h5 className="modal-title" id="coverLetterModalLabel">
                    Generate Cover Letter
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3 aboutme-input">
                    <div className="form-group">
                      <label htmlFor="jobTitle" className="form-label">
                        Job Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobTitle"
                        name="jobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleGenerate}
                      disabled={isLoadingGenerate}
                    >
                      Generate
                    </button>
                  </div>
                  <div className="mb-3 aboutme-input">
                    <div className="form-group generate-coverletter">
                      <label className="form-label">
                        Generated Cover Letter
                      </label>
                      <textarea
                        className="form-control"
                        rows="6"
                        value={generatedCoverLetter}
                      ></textarea>
                      {isLoadingGenerate && (
                        <img
                          className="loading"
                          src={spinner}
                          alt="Loading..."
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {isGenerated && (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleSave}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Use This
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppBarDrawer>
  );
}

EditResume.propTypes = {
  resumeData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    personalInfo: PropTypes.shape({
      years_of_experience: PropTypes.number.isRequired,
      veterans_status: PropTypes.string.isRequired,
      disability_status: PropTypes.bool.isRequired,
      criminal_status: PropTypes.bool.isRequired,
      willing_to_relocate: PropTypes.bool.isRequired,
      ethnicity: PropTypes.string.isRequired,
      notice_period_in_days: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      country_code: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      require_visa_sponsorship: PropTypes.bool.isRequired,
      has_driver_license: PropTypes.bool.isRequired,
      gender: PropTypes.string.isRequired,
      preferred_way_of_working: PropTypes.string.isRequired,
      have_own_vehicle: PropTypes.bool.isRequired,
      has_security_clearance: PropTypes.bool.isRequired,
      message_to_hr: PropTypes.string.isRequired,
      portfolio_url: PropTypes.string.isRequired,
      linkedin_url: PropTypes.string.isRequired,
      github_url: PropTypes.string.isRequired,
      behance_url: PropTypes.string.isRequired,
      dribbble_url: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      cover_letter: PropTypes.string.isRequired
    }).isRequired,
    expected_salary: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        currency: PropTypes.string
      })
    ).isRequired,
    current_salary: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        currency: PropTypes.string
      })
    ).isRequired,
    address: PropTypes.arrayOf(
      PropTypes.shape({
        street: PropTypes.string.isRequired,
        town: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        postal_code: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired
      })
    ).isRequired,
    work: PropTypes.arrayOf(
      PropTypes.shape({
        company_name: PropTypes.string.isRequired,
        job_title: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        experience_type: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        currently_working: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired
      })
    ).isRequired,
    education: PropTypes.arrayOf(
      PropTypes.shape({
        degree: PropTypes.string.isRequired,
        school: PropTypes.string.isRequired,
        field_of_study: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        grade: PropTypes.string.isRequired
      })
    ).isRequired,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        years_of_experience: PropTypes.number
      })
    ).isRequired,
    languages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        years_of_experience: PropTypes.number
      })
    ).isRequired,
    achievements: PropTypes.arrayOf(
      PropTypes.shape({
        award_name: PropTypes.string.isRequired,
        issued_by: PropTypes.string.isRequired,
        achievement_date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ).isRequired,
    certifications: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        issued_by: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        certificate_id: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ).isRequired
  })
};

export default EditResume;
